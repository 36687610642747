// Path: src\pages\ProtocoloView.js
import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "../axiosConfig";
import Navbar1 from "../components/Navbar1";
import Footer1 from "../components/Footer1";
import ConstrucaoNovaView from "./tipos/ConstrucaoNovaView";
import ReformaAmpliacaoView from "./tipos/ReformaAmpliacaoView";
import PavimentacaoUrbanaView from "./tipos/PavimentacaoUrbanaView";
import DrenagemUrbanaView from "./tipos/DrenagemUrbanaView";
import EstudoHidrologicoView from "./tipos/EstudoHidrologicoView";
import LevantamentoTopograficoView from "./tipos/LevantamentoTopograficoView";
import EstradaVicinalView from "./tipos/EstradaVicinalView";
import ObraDeArteEspecialView from "./tipos/ObraDeArteEspecialView";
import AmbientalCivilView from "./tipos/AmbientalCivilView";
import AmbientalInfraView from "./tipos/AmbientalInfraView";
import SaneamentoView from "./tipos/SaneamentoView";
import LoteamentoView from "./tipos/LoteamentoView"; // Importa o componente LoteamentoView

const ProtocoloView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [protocolo, setProtocolo] = useState(null);

  const fetchProtocolo = useCallback(async () => {
    const endpoints = [
      `/api/protocolos/${id}`,
      `/api/pavimentacao-urbana/${id}`,
      `/api/drenagem-urbana/${id}`,
      `/api/estudo-hidrologico/${id}`,
      `/api/levantamento-topografico/${id}`,
      `/api/estrada-vicinal/${id}`,
      `/api/obra-de-arte-especial/${id}`,
      `/api/ambiental-civil/${id}`,
      `/api/ambiental-infra/${id}`,
      `/api/saneamento/${id}`,
      `/api/loteamento/${id}`, // Nova rota para Loteamento
    ];

    try {
      const responses = await Promise.all(
        endpoints.map((endpoint) => axios.get(endpoint).catch(() => null))
      );

      const protocoloData = responses.find(
        (response) => response && response.data
      );

      if (protocoloData) {
        setProtocolo(protocoloData.data);
      } else {
        console.error("Nenhum protocolo encontrado com o ID fornecido");
      }
    } catch (error) {
      console.error("Erro ao buscar protocolo:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchProtocolo();
  }, [fetchProtocolo]);

  const renderProtocoloView = () => {
    if (!protocolo) {
      return <h2>Carregando protocolo...</h2>;
    }

    switch (protocolo.tipo) {
      case "Construção nova":
        return <ConstrucaoNovaView protocolo={protocolo} />;
      case "Reforma e Ampliação":
        return <ReformaAmpliacaoView protocolo={protocolo} />;
      case "Pavimentação Urbana":
        return <PavimentacaoUrbanaView protocolo={protocolo} />;
      case "Drenagem Urbana":
        return <DrenagemUrbanaView protocolo={protocolo} />;
      case "Estudo Hidrológico":
        return <EstudoHidrologicoView protocolo={protocolo} />;
      case "Levantamento Topográfico":
        return <LevantamentoTopograficoView protocolo={protocolo} />;
      case "Estrada Vicinal":
        return <EstradaVicinalView protocolo={protocolo} />;
      case "Obra de Arte Especial":
        return <ObraDeArteEspecialView protocolo={protocolo} />;
      case "Ambiental Civil":
        return <AmbientalCivilView protocolo={protocolo} />;
      case "Ambiental Infra":
      case "Ambiental Infraestrutura":
        return <AmbientalInfraView protocolo={protocolo} />;
      case "Saneamento":
        return <SaneamentoView protocolo={protocolo} />;
      case "Loteamento":
        return <LoteamentoView protocolo={protocolo} />; // Novo caso para Loteamento
      default:
        return <h2>Tipo de protocolo não reconhecido</h2>;
    }
  };

  return (
    <>
      <Navbar1 />
      <div className="container mt-5">
        {renderProtocoloView()}
        <Button
          variant="secondary"
          onClick={() => navigate(-1)}
          className="mt-4"
        >
          Voltar
        </Button>
      </div>
      <Footer1 />
    </>
  );
};

export default ProtocoloView;

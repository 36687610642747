// Path: src\pages\ManageUser.js
import React, { useEffect, useState, useContext } from "react";
import axios from "../axiosConfig";
import AuthContext from "../context/AuthContext";
import { Button, Form } from "react-bootstrap";
import Navbar1 from "../components/Navbar1";
import Footer1 from "../components/Footer1";
import { useNavigate } from "react-router-dom";

const ManageUser = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate(); // Hook para redirecionamento
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    jobTitle: "",
    phone: "",
    password: "",
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`/api/auth/user`);
        setFormData({
          fullName: response.data.fullName,
          email: response.data.email,
          jobTitle: response.data.jobTitle,
          phone: response.data.phone,
          password: "",
        });
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateData = {
        fullName: formData.fullName,
        email: formData.email,
        jobTitle: formData.jobTitle,
        phone: formData.phone,
      };

      if (formData.password) {
        updateData.password = formData.password;
      }

      await axios.put(`/api/auth/user/${user._id}`, updateData);
      alert("User updated successfully");

      // Redirecionamento baseado na role
      if (user.role === "admin") {
        navigate("/admin-dashboard");
      } else {
        navigate("/user-dashboard");
      }
      window.location.reload();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <>
      <Navbar1 />
      <div className="container mt-5">
        <h2>Gerenciar sua Conta</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Nome Completo</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Cargo</Form.Label>
            <Form.Control
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Celular</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nova Senha</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Deixe vazio se não for alterar"
              value={formData.password}
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Salvar
          </Button>
        </Form>
      </div>
      <Footer1 />
    </>
  );
};

export default ManageUser;

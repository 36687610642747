// Path: src\components\LoteamentoForm.js
import React, { useState } from "react";
import { Form, Button, Alert, ProgressBar } from "react-bootstrap";
import axios from "../axiosConfig";
import { useNavigate } from "react-router-dom";

const LoteamentoForm = ({ onSuccess }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nomeObjeto: "",
    oficio: null,
    tipoEsgoto: "",
    estudoHidrogeologico: null,
    fonteAbastecimento: "Rede Pública",
    gerenciamento: "",
    cadastroRedeAgua: null,
    licenciamentoPoco: null,
    testeBombeamento: null,
    analiseQualidadeAgua: null,
    documentoPosse: null,
    mapaPlanialtimetrico: null,
    numeroLotes: "",
    ensaiosGeotecnicosCBR: null,
    ensaiosJazida: null,
    licencaAmbientalJazida: null,
    cadastroRedeDrenagem: null,
  });

  const [error, setError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleNext = () => setStep(step + 1);
  const handlePrevious = () => setStep(step - 1);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const data = new FormData();
      for (const key in formData) {
        if (formData[key]) {
          data.append(key, formData[key]);
        }
      }

      const response = await axios.post("/api/protocolos/loteamento", data, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      const createdProtocoloId = response.data.protocolo?._id;
      if (createdProtocoloId) navigate(`/protocolo/${createdProtocoloId}`);
      else throw new Error("ID do protocolo não foi retornado");
    } catch {
      setError("Erro ao criar protocolo. Por favor, tente novamente.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStep1 = () => (
    <>
      <h4>Dados Gerais</h4>
      <Form.Group className="mb-3">
        <Form.Label>Nome do Objeto</Form.Label>
        <Form.Control
          type="text"
          name="nomeObjeto"
          value={formData.nomeObjeto}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Ofício (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="oficio"
          onChange={handleChange}
          required
        />
      </Form.Group>
    </>
  );

  const renderStep2 = () => (
    <>
      <h4>Licenciamento</h4>
      <Form.Group className="mb-3">
        <Form.Label>Tipo de Tratamento do Esgoto</Form.Label>
        <Form.Control
          as="select"
          name="tipoEsgoto"
          value={formData.tipoEsgoto}
          onChange={handleChange}
        >
          <option>Rede Pública de Esgotamento Sanitário</option>
          <option>Sistema de Tratamento Individual</option>
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Estudo Hidrogeológico</Form.Label>
        <Form.Control
          type="file"
          name="estudoHidrogeologico"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderStep3 = () => (
    <>
      <h4>Abastecimento de Água</h4>
      <Form.Group className="mb-3">
        <Form.Label>Fonte de Abastecimento</Form.Label>
        <Form.Control
          as="select"
          name="fonteAbastecimento"
          value={formData.fonteAbastecimento}
          onChange={handleChange}
        >
          <option>Rede Pública</option>
          <option>Poço</option>
        </Form.Control>
      </Form.Group>
      {formData.fonteAbastecimento === "Rede Pública" && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Gerenciamento</Form.Label>
            <Form.Control
              as="select"
              name="gerenciamento"
              value={formData.gerenciamento}
              onChange={handleChange}
            >
              <option>Municipal</option>
              <option>Concessionária</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Cadastro de Rede de Água Existente</Form.Label>
            <Form.Control
              type="file"
              name="cadastroRedeAgua"
              onChange={handleChange}
            />
          </Form.Group>
        </>
      )}
      {formData.fonteAbastecimento === "Poço" && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Licenciamento do Poço</Form.Label>
            <Form.Control
              type="file"
              name="licenciamentoPoco"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Teste de Bombeamento</Form.Label>
            <Form.Control
              type="file"
              name="testeBombeamento"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Análise de Qualidade da Água</Form.Label>
            <Form.Control
              type="file"
              name="analiseQualidadeAgua"
              onChange={handleChange}
            />
          </Form.Group>
        </>
      )}
    </>
  );

  const renderStep4 = () => (
    <>
      <h4>Parcelamento do Solo</h4>
      <Form.Group className="mb-3">
        <Form.Label>Documento de Posse da Área</Form.Label>
        <Form.Control
          type="file"
          name="documentoPosse"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Mapa Planialtimétrico do Loteamento</Form.Label>
        <Form.Control
          type="file"
          name="mapaPlanialtimetrico"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Número de Lotes Desejados</Form.Label>
        <Form.Control
          type="text"
          name="numeroLotes"
          value={formData.numeroLotes}
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderStep5 = () => (
    <>
      <h4>Pavimentação Asfáltica</h4>
      <Form.Group className="mb-3">
        <Form.Label>Ensaios Geotécnicos (CBR)</Form.Label>
        <Form.Control
          type="file"
          name="ensaiosGeotecnicosCBR"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Ensaios Geotécnicos da Jazida</Form.Label>
        <Form.Control
          type="file"
          name="ensaiosJazida"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Licença Ambiental da Jazida</Form.Label>
        <Form.Control
          type="file"
          name="licencaAmbientalJazida"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderStep6 = () => (
    <>
      <h4>Drenagem</h4>
      <Form.Group className="mb-3">
        <Form.Label>Cadastro de Rede de Drenagem Existente</Form.Label>
        <Form.Control
          type="file"
          name="cadastroRedeDrenagem"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      {uploadProgress > 0 && uploadProgress < 100 && (
        <ProgressBar
          now={uploadProgress}
          label={`${uploadProgress}%`}
          className="mb-3"
        />
      )}

      {step === 1 && renderStep1()}
      {step === 2 && renderStep2()}
      {step === 3 && renderStep3()}
      {step === 4 && renderStep4()}
      {step === 5 && renderStep5()}
      {step === 6 && renderStep6()}

      <div className="d-flex justify-content-between mt-4">
        {step > 1 && (
          <Button variant="secondary" onClick={handlePrevious}>
            Voltar
          </Button>
        )}
        {step < 6 && (
          <Button variant="primary" onClick={handleNext}>
            Próxima Fase
          </Button>
        )}
        {step === 6 && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Enviando..." : "Enviar Solicitação"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default LoteamentoForm;

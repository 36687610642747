// Path: src\components\AuthLayout.js
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

// components
import SwiperSlider1 from "./SwiperSlider1";

// images
import logoCentral from "../assets/images/logo.svg";
import logoAMM from "../assets/images/brasao_padrao.svg";

const AuthLayout = ({ hasSlider, children, bottomLinks }) => {
  return (
    <div className="bg-gradient2 vh-100 d-flex align-items-center justify-content-center pt-1 pb-2">
      <Container className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col xl={hasSlider ? 12 : 6} md={hasSlider ? 12 : 10}>
            <Card className="h-100">
              <Card.Body className="p-0 h-100">
                <Row className="g-0 h-100">
                  <Col
                    xs={12}
                    xl={hasSlider ? 5 : 12}
                    className={`${
                      hasSlider ? "shadow" : ""
                    } d-flex flex-column justify-content-center`}
                  >
                    <div className="p-xl-4 p-2">
                      <div className="d-flex flex-column flex-xl-row justify-content-center align-items-center mb-4">
                        <img
                          src={logoCentral}
                          alt="Logo Central de Projetos"
                          height="90"
                          className="mb-3 mb-xl-0 me-xl-3"
                        />
                        <img src={logoAMM} alt="Logo AMM" height="70" />
                      </div>
                      {children}
                    </div>
                  </Col>
                  {hasSlider && (
                    <Col
                      xl={7}
                      className="d-none d-xl-flex align-items-center justify-content-center"
                    >
                      <div className="swiper-container h-100 w-100">
                        <SwiperSlider1 />
                      </div>
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12} className="text-center">
                {bottomLinks}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

AuthLayout.defaultProps = {
  hasSlider: false,
};

export default AuthLayout;

// Path: src\pages\Register.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Button, Alert, Form } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask"; // Importando o InputMask
import AuthLayout from "../components/AuthLayout";
import { fetchMunicipalities } from "../utils/fetchMunicipalities";

const apiUrl = process.env.REACT_APP_API_URL;
console.log("API URL:", apiUrl);

const Register = () => {
  const [error, setError] = useState("");
  const [municipalities, setMunicipalities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // Novo estado para controle de envio
  const navigate = useNavigate();

  useEffect(() => {
    const loadMunicipalities = async () => {
      const municipalities = await fetchMunicipalities();
      setMunicipalities(municipalities);
    };

    loadMunicipalities();
  }, []);

  const schema = yup.object().shape({
    fullName: yup.string().required("Por favor, insira seu nome completo"),
    email: yup
      .string()
      .required("Por favor, insira seu email")
      .email("Por favor, insira um email válido"),
    jobTitle: yup.string().required("Por favor, insira seu cargo/função"),
    phone: yup.string().required("Por favor, insira seu telefone"),
    municipality: yup.string().required("Por favor, selecione o município"),
    password: yup.string().required("Por favor, insira sua senha"),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true); // Define como enviando
    setError(""); // Limpa o erro anterior

    try {
      await axios.post(`${apiUrl}/api/auth/register`, data);
      alert("Seu registro foi enviado para aprovação.");
      navigate("/login");
    } catch (err) {
      console.error("Error registering user:", err);
      setError(err.response?.data?.message || "Erro ao registrar usuário");
    } finally {
      setIsSubmitting(false); // Libera o botão após o envio
    }
  };

  return (
    <AuthLayout
      bottomLinks={
        <p className="text-muted">
          Já tem uma conta?{" "}
          <Link to="/login" className="text-primary fw-semibold ms-1">
            Log In
          </Link>
        </p>
      }
    >
      <h6 className="h5 mb-0 mt-3">Crie sua conta</h6>
      <p className="text-muted mt-1 mb-4">
        Não tem uma conta? Crie sua conta em menos de um minuto.
      </p>

      {error && (
        <Alert variant="danger" className="mb-3">
          {error}
        </Alert>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>Nome Completo</Form.Label>
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => <Form.Control type="text" {...field} />}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => <Form.Control type="email" {...field} />}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Cargo/Função</Form.Label>
          <Controller
            name="jobTitle"
            control={control}
            render={({ field }) => <Form.Control type="text" {...field} />}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Telefone</Form.Label>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <InputMask
                mask="(99) 99999-9999"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
              >
                {(inputProps) => <Form.Control type="text" {...inputProps} />}
              </InputMask>
            )}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Município</Form.Label>
          <Controller
            name="municipality"
            control={control}
            render={({ field }) => (
              <Form.Control as="select" {...field}>
                <option value="">Selecione o Município</option>
                {municipalities.map((municipio) => (
                  <option key={municipio} value={municipio}>
                    {municipio}
                  </option>
                ))}
              </Form.Control>
            )}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Senha</Form.Label>
          <Controller
            name="password"
            control={control}
            render={({ field }) => <Form.Control type="password" {...field} />}
          />
        </Form.Group>

        <div className="mb-0 text-center d-grid">
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Registrando, aguarde..." : "Registrar"}
          </Button>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default Register;

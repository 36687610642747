// Path: src\pages\AdminProtocoloView.js
import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "../axiosConfig";
import Navbar1 from "../components/Navbar1";
import Footer1 from "../components/Footer1";
import AdminConstrucaoNovaView from "./tipos/AdminConstrucaoNovaView";
import AdminReformaAmpliacaoView from "./tipos/AdminReformaAmpliacaoView";
import AdminPavimentacaoUrbanaView from "./tipos/AdminPavimentacaoUrbanaView";
import AdminDrenagemUrbanaView from "./tipos/AdminDrenagemUrbanaView";
import AdminEstudoHidrologicoView from "./tipos/AdminEstudoHidrologicoView";
import AdminLevantamentoTopograficoView from "./tipos/AdminLevantamentoTopograficoView";
import AdminEstradaVicinalView from "./tipos/AdminEstradaVicinalView";
import AdminObraDeArteEspecialView from "./tipos/AdminObraDeArteEspecialView";
import AdminAmbientalCivilView from "./tipos/AdminAmbientalCivilView";
import AdminAmbientalInfraView from "./tipos/AdminAmbientalInfraView";
import AdminSaneamentoView from "./tipos/AdminSaneamentoView";
import AdminLoteamentoView from "./tipos/AdminLoteamentoView";

const AdminProtocoloView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [protocolo, setProtocolo] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [justification, setJustification] = useState("");

  const fetchProtocolo = useCallback(async () => {
    const endpoints = [
      `/api/protocolos/${id}`,
      `/api/saneamento/${id}`,
      `/api/pavimentacao-urbana/${id}`,
      `/api/drenagem-urbana/${id}`,
      `/api/estudo-hidrologico/${id}`,
      `/api/levantamento-topografico/${id}`,
      `/api/estrada-vicinal/${id}`,
      `/api/obra-de-arte-especial/${id}`,
      `/api/ambiental-civil/${id}`,
      `/api/ambiental-infra/${id}`,
      `/api/loteamento/${id}`,
    ];

    try {
      const responses = await Promise.all(
        endpoints.map((endpoint) => axios.get(endpoint).catch(() => null))
      );

      const protocoloData = responses.find(
        (response) => response && response.data
      );

      if (protocoloData) {
        setProtocolo(protocoloData.data);
      } else {
        console.error("Nenhum protocolo encontrado com o ID fornecido");
      }
    } catch (error) {
      console.error("Erro ao buscar protocolo:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchProtocolo();
  }, [fetchProtocolo]);

  const getProtocolRoute = (protocoloId, tipo) => {
    switch (tipo) {
      case "Pavimentação Urbana":
        return `/api/pavimentacao-urbana/${protocoloId}`;
      case "Drenagem Urbana":
        return `/api/drenagem-urbana/${protocoloId}`;
      case "Estudo Hidrológico":
        return `/api/estudo-hidrologico/${protocoloId}`;
      case "Levantamento Topográfico":
        return `/api/levantamento-topografico/${protocoloId}`;
      case "Estrada Vicinal":
        return `/api/estrada-vicinal/${protocoloId}`;
      case "Obra de Arte Especial":
        return `/api/obra-de-arte-especial/${protocoloId}`;
      case "Ambiental Civil":
        return `/api/ambiental-civil/${protocoloId}`;
      case "Ambiental Infraestrutura":
        return `/api/ambiental-infra/${protocoloId}`;
      case "Saneamento":
        return `/api/saneamento/${protocoloId}`;
      case "Loteamento":
        return `/api/loteamento/${protocoloId}`;
      default:
        return `/api/protocolos/${protocoloId}`;
    }
  };

  const handleApprove = async () => {
    try {
      const route = getProtocolRoute(id, protocolo.tipo);
      await axios.put(`${route}/aprovar`);
      setProtocolo((prevProtocolo) => ({
        ...prevProtocolo,
        status: "aprovado",
      }));
      navigate(`/admin/protocolo/${id}`);
      navigate(0);
    } catch (error) {
      console.error("Erro ao aprovar protocolo:", error);
    }
  };

  const handleReject = async () => {
    try {
      const route = getProtocolRoute(id, protocolo.tipo);
      await axios.put(`${route}/rejeitar`, { justification });
      setProtocolo((prevProtocolo) => ({
        ...prevProtocolo,
        status: "rejeitado",
        rejectionJustification: justification,
      }));
      navigate(`/admin/protocolo/${id}`);
      navigate(0);
    } catch (error) {
      console.error("Erro ao rejeitar protocolo:", error);
    }
  };

  const handleSetPending = async () => {
    try {
      const route = getProtocolRoute(id, protocolo.tipo);
      await axios.put(`${route}/pendente`);
      setProtocolo((prevProtocolo) => ({
        ...prevProtocolo,
        status: "pendente",
      }));
      navigate(`/admin/protocolo/${id}`);
      navigate(0);
    } catch (error) {
      console.error("Erro ao definir protocolo como pendente:", error);
    }
  };

  const handleShowRejectModal = () => {
    setShowRejectModal(true);
  };

  const handleCloseRejectModal = () => {
    setShowRejectModal(false);
    setJustification("");
  };

  const renderProtocoloView = () => {
    if (!protocolo) {
      return <h2>Carregando protocolo...</h2>;
    }

    switch (protocolo.tipo) {
      case "Construção nova":
        return <AdminConstrucaoNovaView protocolo={protocolo} />;
      case "Reforma e Ampliação":
        return <AdminReformaAmpliacaoView protocolo={protocolo} />;
      case "Pavimentação Urbana":
        return <AdminPavimentacaoUrbanaView protocolo={protocolo} />;
      case "Drenagem Urbana":
        return <AdminDrenagemUrbanaView protocolo={protocolo} />;
      case "Estudo Hidrológico":
        return <AdminEstudoHidrologicoView protocolo={protocolo} />;
      case "Levantamento Topográfico":
        return <AdminLevantamentoTopograficoView protocolo={protocolo} />;
      case "Estrada Vicinal":
        return <AdminEstradaVicinalView protocolo={protocolo} />;
      case "Obra de Arte Especial":
        return <AdminObraDeArteEspecialView protocolo={protocolo} />;
      case "Ambiental Civil":
        return <AdminAmbientalCivilView protocolo={protocolo} />;
      case "Ambiental Infraestrutura":
        return <AdminAmbientalInfraView protocolo={protocolo} />;
      case "Saneamento":
        return <AdminSaneamentoView protocolo={protocolo} />;
      case "Loteamento":
        return <AdminLoteamentoView protocolo={protocolo} />;
      default:
        return <h2>Tipo de protocolo não reconhecido</h2>;
    }
  };

  const renderActions = () => {
    if (!protocolo) return null;

    switch (protocolo.status) {
      case "pendente":
        return (
          <>
            <Button variant="success" onClick={handleApprove} className="me-2">
              Aprovar
            </Button>
            <Button variant="danger" onClick={handleShowRejectModal}>
              Rejeitar
            </Button>
          </>
        );
      case "aprovado":
        return (
          <>
            <Button
              variant="warning"
              onClick={handleSetPending}
              className="me-2"
            >
              Pendente
            </Button>
            <Button variant="danger" onClick={handleShowRejectModal}>
              Rejeitar
            </Button>
          </>
        );
      case "rejeitado":
        return (
          <>
            <Button
              variant="warning"
              onClick={handleSetPending}
              className="me-2"
            >
              Pendente
            </Button>
            <Button variant="success" onClick={handleApprove}>
              Aprovar
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar1 />
      <div className="container mt-5">
        {renderProtocoloView()}
        <div className="mt-4">
          {renderActions()}
          <Button
            variant="secondary"
            onClick={() => navigate(-1)}
            className="ms-2"
          >
            Voltar
          </Button>
        </div>
      </div>
      <Footer1 />

      {/* Modal for Rejection Justification */}
      <Modal show={showRejectModal} onHide={handleCloseRejectModal}>
        <Modal.Header closeButton>
          <Modal.Title>Justificativa da Rejeição</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                Por favor, forneça uma justificativa para a rejeição:
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={justification}
                onChange={(e) => setJustification(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRejectModal}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleReject}>
            Rejeitar Protocolo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminProtocoloView;

// Path: src\pages\tipos\AmbientalInfraView.js
import React, { useState } from "react";
import { Table, Card, Button, Badge, Form } from "react-bootstrap";
import axios from "../../axiosConfig";

const AmbientalInfraView = ({ protocolo }) => {
  const [responses, setResponses] = useState({});
  const [fileResponses, setFileResponses] = useState({});
  const [protocoloData, setProtocoloData] = useState(protocolo);
  const [isSubmitting, setIsSubmitting] = useState({});

  const handleResponseChange = (issueId, value) => {
    setResponses({
      ...responses,
      [issueId]: value,
    });
  };

  const handleFileChange = (issueId, file) => {
    setFileResponses({
      ...fileResponses,
      [issueId]: file,
    });
  };

  const handleResponseSubmit = async (issueId) => {
    setIsSubmitting((prev) => ({ ...prev, [issueId]: true }));
    try {
      const response = responses[issueId];
      const fileResponse = fileResponses[issueId];

      let updatedIssue;

      if (fileResponse) {
        const formData = new FormData();
        formData.append("file", fileResponse);

        const result = await axios.post(
          `/api/ambiental-infra/${protocolo._id}/pendencias/${issueId}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        updatedIssue = result.data.protocolo.pendingIssues.find(
          (issue) => issue._id === issueId
        );
      } else if (response) {
        const result = await axios.put(
          `/api/ambiental-infra/${protocolo._id}/pendencias/${issueId}/responder`,
          {
            response,
          }
        );
        updatedIssue = result.data.protocolo.pendingIssues.find(
          (issue) => issue._id === issueId
        );
      }

      if (updatedIssue) {
        setProtocoloData((prevState) => {
          const updatedPendingIssues = prevState.pendingIssues.map((issue) =>
            issue._id === issueId ? updatedIssue : issue
          );
          return { ...prevState, pendingIssues: updatedPendingIssues };
        });
      }

      setResponses((prev) => ({ ...prev, [issueId]: "" }));
      setFileResponses((prev) => ({ ...prev, [issueId]: null }));
    } catch (error) {
      console.error("Erro ao enviar resposta:", error);
    } finally {
      setIsSubmitting((prev) => ({ ...prev, [issueId]: false })); // Desativa o estado de envio após a conclusão
    }
  };

  const renderStatusTag = (status) => {
    switch (status) {
      case "pendente":
        return <Badge bg="warning">Pendente</Badge>;
      case "respondido":
        return <Badge bg="success">Respondido</Badge>;
      case "aprovado":
        return <Badge bg="primary">Aprovado</Badge>;
      default:
        return null;
    }
  };

  const renderPendingIssues = (fieldName, fieldValue) => {
    const normalizedFieldName = fieldName.trim().toLowerCase();
    const issues = protocoloData.pendingIssues?.filter(
      (issue) => issue.fieldName.trim().toLowerCase() === normalizedFieldName
    );

    const isFileUpload =
      (React.isValidElement(fieldValue) && fieldValue.type === "a") ||
      (typeof fieldValue === "string" &&
        fieldValue === "Arquivo Não informado");

    return issues && issues.length > 0 ? (
      <ul>
        {issues.map((issue, index) => (
          <li key={index}>
            {issue.description} {renderStatusTag(issue.status)}
            {issue.status === "pendente" && (
              <div style={{ marginTop: "10px" }}>
                {isFileUpload ? (
                  <div>
                    <Form.Control
                      type="file"
                      onChange={(e) =>
                        handleFileChange(issue._id, e.target.files[0])
                      }
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                ) : (
                  <Form.Control
                    type="text"
                    placeholder="Responda aqui..."
                    value={responses[issue._id] || ""}
                    onChange={(e) =>
                      handleResponseChange(issue._id, e.target.value)
                    }
                  />
                )}
                <Button
                  onClick={() => handleResponseSubmit(issue._id)}
                  variant="primary"
                  size="sm"
                  style={{ marginTop: "5px" }}
                  disabled={isSubmitting[issue._id]} // Desativa o botão durante o envio
                >
                  {isSubmitting[issue._id] ? "Enviando..." : "Enviar Resposta"}
                </Button>
              </div>
            )}
            {issue.status === "respondido" && issue.response && (
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "5px",
                  color: "#28a745",
                }}
              >
                <strong>Resposta:</strong> {issue.response}
                <br />
                <small>
                  Respondido em: {new Date(issue.responseDate).toLocaleString()}
                </small>
              </div>
            )}
            {issue.status === "respondido" && issue.responseFileUrl && (
              <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                <strong>Arquivo enviado:</strong>{" "}
                <a
                  href={issue.responseFileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {issue.responseFileName || "Visualizar arquivo"}
                </a>
                <br />
                <small>
                  Respondido em: {new Date(issue.responseDate).toLocaleString()}
                </small>
              </div>
            )}
          </li>
        ))}
      </ul>
    ) : (
      ""
    );
  };

  const renderDocumentLink = (label, url) =>
    url ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        Visualizar documento
      </a>
    ) : (
      "Arquivo Não informado"
    );

  const renderSection = (title, fields) => (
    <div className="mb-4">
      <h5 className="section-title">{title}</h5>
      <Table striped bordered hover className="custom-table">
        <thead>
          <tr>
            <th style={{ width: "450px" }}>Campo</th>
            <th>Valor</th>
            <th style={{ width: "400px" }}>Pendências</th>
          </tr>
        </thead>
        <tbody>
          {fields.map(([label, value], index) => (
            <tr key={index}>
              <td>
                <strong>{label}</strong>
              </td>
              <td>{value || "Não informado"}</td>
              <td>{renderPendingIssues(label, value)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "aprovado":
        return "#28a745"; // Verde
      case "pendente":
        return "#ffc107"; // Amarelo
      case "rejeitado":
        return "#dc3545"; // Vermelho
      default:
        return "#6c757d"; // Cinza (para status desconhecidos)
    }
  };

  return (
    <div>
      <Card
        className="mb-4"
        style={{ backgroundColor: "#007bff", color: "white" }}
      >
        <Card.Body>
          <h2 className="texto">
            {protocolo.nomeObjeto} - {protocolo.usuario.municipality}
          </h2>
        </Card.Body>
      </Card>

      <Card
        className="mb-4"
        style={{
          backgroundColor: getStatusColor(protocolo.status),
          color: "white",
          height: "50px",
          lineHeight: "30px",
          padding: "10px 20px",
        }}
      >
        <Card.Body className="p-0 d-flex align-items-center">
          <h4 className="m-0">
            Status:{" "}
            {protocolo.status.charAt(0).toUpperCase() +
              protocolo.status.slice(1)}
          </h4>
        </Card.Body>
      </Card>

      {protocolo.status === "rejeitado" && protocolo.rejectionJustification && (
        <Card
          className="mb-4"
          style={{ backgroundColor: "#dc3545", color: "white" }}
        >
          <Card.Body>
            <h4>Justificativa da Rejeição</h4>
            <p>{protocolo.rejectionJustification}</p>
          </Card.Body>
        </Card>
      )}

      <h2>Detalhes do Protocolo - Ambiental Infraestrutura</h2>

      {renderSection("Dados Gerais", [
        ["Número do Protocolo", protocolo.numeroProtocolo],
        ["Nome da Solicitação", protocolo.nomeObjeto],
        ["Tipo de Solicitação", protocolo.subtipoProjeto],
        [
          "Ofício",
          renderDocumentLink("Ofício", protocolo.oficioSolicitacaoUrl),
        ],
        ["Data de Criação", new Date(protocolo.createdAt).toLocaleString()],
        ["Data de Atualização", new Date(protocolo.updatedAt).toLocaleString()],
      ])}

      {renderSection("Dados do Solicitante", [
        ["Nome Completo", protocolo.usuario.fullName],
        ["Email", protocolo.usuario.email],
        ["Cargo", protocolo.usuario.jobTitle],
        ["Telefone", protocolo.usuario.phone],
        ["Município", protocolo.usuario.municipality],
      ])}

      {/* Exibe as seções com base no subtipo do protocolo */}
      {protocolo.subtipoProjeto === "botaFora" &&
        renderSection("Bota-fora", [
          [
            "Documentos do Prefeito",
            renderDocumentLink(
              "Documentos do Prefeito",
              protocolo.documentosPrefeitoUrl
            ),
          ],
          [
            "Área Proposta",
            renderDocumentLink("Área Proposta", protocolo.areaPropostaUrl),
          ],
          [
            "Relatório Fotográfico",
            renderDocumentLink(
              "Relatório Fotográfico",
              protocolo.relatorioFotograficoUrl
            ),
          ],
          [
            "Documento da Área",
            renderDocumentLink("Documento da Área", protocolo.documentoAreaUrl),
          ],
          [
            "Imagem do Polígono da Área",
            renderDocumentLink(
              "Imagem do Polígono da Área",
              protocolo.imagemPoligonoUrl
            ),
          ],
          [
            "Procuração do Prefeito",
            renderDocumentLink(
              "Procuração do Prefeito",
              protocolo.procuracaoPrefeitoUrl
            ),
          ],
          [
            "Declaração de Uso e Ocupação do Solo",
            renderDocumentLink(
              "Declaração de Uso e Ocupação do Solo",
              protocolo.declaracaoUsoSoloUrl
            ),
          ],
          [
            "Pagamento da ART",
            renderDocumentLink("Pagamento da ART", protocolo.pagamentoArtUrl),
          ],
          [
            "Comprovante de Ligação de Água",
            renderDocumentLink(
              "Comprovante de Ligação de Água",
              protocolo.comprovanteAguaUrl
            ),
          ],
          [
            "ART do Engenheiro",
            renderDocumentLink("ART do Engenheiro", protocolo.artEngenheiroUrl),
          ],
        ])}

      {protocolo.subtipoProjeto === "captacaoSuperficial" &&
        renderSection("Captação Superficial", [
          [
            "Identificação do Corpo Hídrico",
            protocolo.identificacaoCorpoHidrico,
          ],
          [
            "Coordenadas do Ponto de Captação",
            protocolo.coordenadasPontoCaptacao,
          ],
          [
            "Identificação do Sistema de Captação",
            protocolo.identificacaoSistemaCaptacao,
          ],
          ["Dados Técnicos da Captação", protocolo.dadosTecnicosCaptacao],
          [
            "Procuração do Prefeito",
            renderDocumentLink(
              "Procuração do Prefeito",
              protocolo.procuracaoPrefeitoUrl
            ),
          ],
          [
            "Documentos do Prefeito",
            renderDocumentLink(
              "Documentos do Prefeito",
              protocolo.documentosPrefeitoUrl
            ),
          ],
          [
            "Publicação do Pedido de Outorga",
            renderDocumentLink(
              "Publicação do Pedido de Outorga",
              protocolo.publicacaoOutorgaUrl
            ),
          ],
          [
            "Declaração de Uso e Ocupação do Solo",
            renderDocumentLink(
              "Declaração de Uso e Ocupação do Solo",
              protocolo.declaracaoUsoSoloUrl
            ),
          ],
        ])}

      {protocolo.subtipoProjeto === "sistemaEsgotamentoSanitario" &&
        renderSection("Sistema de Esgotamento Sanitário", [
          [
            "Identificação do Corpo Hídrico Receptor",
            protocolo.identificacaoCorpoHidricoReceptor,
          ],
          [
            "Coordenadas do Ponto de Lançamento",
            protocolo.coordenadasPontoLancamento,
          ],
          ["Dados Técnicos do Sistema", protocolo.dadosTecnicosSistema],
          [
            "Fluxograma do Sistema de Tratamento",
            renderDocumentLink(
              "Fluxograma do Sistema de Tratamento",
              protocolo.fluxogramaSistemaTratamentoUrl
            ),
          ],
          [
            "Boletim de Análise do Efluente",
            renderDocumentLink(
              "Boletim de Análise do Efluente",
              protocolo.boletimAnaliseEfluenteUrl
            ),
          ],
          [
            "Procuração do Prefeito",
            renderDocumentLink(
              "Procuração do Prefeito",
              protocolo.procuracaoPrefeitoUrl
            ),
          ],
          [
            "Documentos do Prefeito",
            renderDocumentLink(
              "Documentos do Prefeito",
              protocolo.documentosPrefeitoUrl
            ),
          ],
          [
            "Declaração de Uso e Ocupação do Solo",
            renderDocumentLink(
              "Declaração de Uso e Ocupação do Solo",
              protocolo.declaracaoUsoSoloUrl
            ),
          ],
        ])}
    </div>
  );
};

export default AmbientalInfraView;

// Path: src\pages\tipos\AdminSaneamentoView.js
import React, { useState, useEffect } from "react";
import { Table, Card, Button, Badge } from "react-bootstrap";
import PendenciasModal from "../../components/PendenciasModalSaneamento";
import axios from "../../axiosConfig";

const AdminSaneamentoView = ({ protocolo }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedField, setSelectedField] = useState("");
  const [issues, setIssues] = useState(protocolo.pendingIssues || []);

  useEffect(() => {
    setIssues(protocolo.pendingIssues || []);
  }, [protocolo]);

  const handleIssueAdded = async () => {
    try {
      const response = await axios.get(`/api/saneamento/${protocolo._id}`);
      setIssues(response.data.pendingIssues);
    } catch (error) {
      console.error("Erro ao atualizar as pendências:", error);
    }
  };

  const renderStatusTag = (status) => {
    switch (status) {
      case "pendente":
        return <Badge bg="warning">Pendente</Badge>;
      case "respondido":
        return <Badge bg="success">Respondido</Badge>;
      case "aprovado":
        return <Badge bg="primary">Aprovado</Badge>;
      default:
        return null;
    }
  };

  const renderPendingIssues = (fieldName) => {
    const fieldIssues = issues.filter((issue) => issue.fieldName === fieldName);
    return fieldIssues.length > 0 ? (
      <ul>
        {fieldIssues.map((issue, index) => (
          <li key={index} style={{ marginBottom: "10px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {issue.description} {renderStatusTag(issue.status)}
              {issue.status === "pendente"}
            </div>
            {issue.status === "respondido" && issue.response && (
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "5px",
                  color: "#28a745",
                }}
              >
                <strong>Resposta:</strong> {issue.response}
                <br />
                <small>
                  Respondido em: {new Date(issue.responseDate).toLocaleString()}
                </small>
              </div>
            )}
            {issue.status === "respondido" && issue.responseFileUrl && (
              <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                <strong>Arquivo enviado:</strong>{" "}
                <a
                  href={issue.responseFileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {issue.responseFileName || "Visualizar arquivo"}
                </a>
                <br />
                <small>
                  Respondido em: {new Date(issue.responseDate).toLocaleString()}
                </small>
              </div>
            )}
          </li>
        ))}
      </ul>
    ) : (
      ""
    );
  };

  const renderDocumentLink = (label, url) =>
    url ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        Visualizar documento
      </a>
    ) : (
      "Arquivo Não informado"
    );

  const renderSection = (title, fields) => (
    <div className="mb-4">
      <h5 className="section-title">{title}</h5>
      <Table striped bordered hover className="custom-table">
        <thead>
          <tr>
            <th style={{ width: "450px" }}>Campo</th>
            <th>Valor</th>
            <th style={{ width: "550px" }}>Pendências</th>
            <th style={{ width: "100px" }}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {fields.map(([label, value], index) => (
            <tr key={index}>
              <td>
                <strong>{label}</strong>
              </td>
              <td>{value || "Não informado"}</td>
              <td>{renderPendingIssues(label)}</td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setSelectedField(label);
                    setShowModal(true);
                  }}
                  variant="danger"
                  size="sm"
                  style={{ padding: "2px 6px", fontSize: "12px" }}
                >
                  Add Pend.
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "aprovado":
        return "#28a745";
      case "pendente":
        return "#ffc107";
      case "rejeitado":
        return "#dc3545";
      default:
        return "#6c757d";
    }
  };

  return (
    <div>
      <PendenciasModal
        protocoloId={protocolo._id}
        fieldName={selectedField}
        show={showModal}
        onHide={() => setShowModal(false)}
        onIssueAdded={handleIssueAdded}
      />
      <Card
        className="mb-4"
        style={{ backgroundColor: "#007bff", color: "white" }}
      >
        <Card.Body>
          <h2 className="texto">
            {protocolo.nomeObjeto} - {protocolo.usuario.municipality}
          </h2>
        </Card.Body>
      </Card>

      <Card
        className="mb-4"
        style={{
          backgroundColor: getStatusColor(protocolo.status),
          color: "white",
          height: "50px",
          lineHeight: "30px",
          padding: "10px 20px",
        }}
      >
        <Card.Body className="p-0 d-flex align-items-center">
          <h4 className="m-0">
            Status:{" "}
            {protocolo.status.charAt(0).toUpperCase() +
              protocolo.status.slice(1)}
          </h4>
        </Card.Body>
      </Card>

      {protocolo.status === "rejeitado" && protocolo.rejectionJustification && (
        <Card
          className="mb-4"
          style={{ backgroundColor: "#dc3545", color: "white" }}
        >
          <Card.Body>
            <h4>Justificativa da Rejeição</h4>
            <p>{protocolo.rejectionJustification}</p>
          </Card.Body>
        </Card>
      )}

      <h2>Detalhes do Protocolo - Saneamento</h2>

      {renderSection("Dados Gerais", [
        ["Número do Protocolo", protocolo.numeroProtocolo],
        ["Nome da Solicitação", protocolo.nomeObjeto],
        ["Tipo da Solicitação", protocolo.subtipoProjeto],
        [
          "Ofício",
          renderDocumentLink("Ofício", protocolo.oficioSolicitacaoUrl),
        ],
        ["Data de Criação", new Date(protocolo.createdAt).toLocaleString()],
        ["Data de Atualização", new Date(protocolo.updatedAt).toLocaleString()],
      ])}

      {renderSection("Dados do Solicitante", [
        ["Nome Completo", protocolo.usuario.fullName],
        ["Email", protocolo.usuario.email],
        ["Cargo", protocolo.usuario.jobTitle],
        ["Telefone", protocolo.usuario.phone],
        ["Município", protocolo.usuario.municipality],
      ])}

      {/* Exibe as seções com base no subtipo do protocolo */}
      {protocolo.subtipoProjeto === "estacaoTratamentoAgua" &&
        renderSection("Estação de Tratamento de Água", [
          [
            "PMSB – Plano Municipal de Saneamento Básico",
            renderDocumentLink("PMSB", protocolo.pmsbUrl),
          ],
          [
            "Planta Planialtimétrica",
            renderDocumentLink(
              "Planta Planialtimétrica",
              protocolo.plantaPlanialtUrl
            ),
          ],
          ["Tipo de Captação de Água", protocolo.tipoCaptacao],
          ["Estrutura Técnico-Administrativa", protocolo.estruturaTecnica],
          [
            "Alternativas Locacionais",
            renderDocumentLink(
              "Alternativas Locacionais",
              protocolo.alternativasLocacionaisUrl
            ),
          ],
          [
            "Mapa de Situação",
            renderDocumentLink("Mapa de Situação", protocolo.mapaSituacaoUrl),
          ],
          [
            "Fotocópia da Escritura do Terreno",
            renderDocumentLink(
              "Fotocópia da Escritura do Terreno",
              protocolo.escrituraTerrenoUrl
            ),
          ],
          [
            "Caracterização do Manancial",
            renderDocumentLink(
              "Caracterização do Manancial",
              protocolo.caracterizacaoManancialUrl
            ),
          ],
        ])}

      {protocolo.subtipoProjeto === "sistemaEsgotamentoSanitario" &&
        renderSection("Sistema de Esgotamento Sanitário", [
          [
            "PMSB – Plano Municipal de Saneamento Básico",
            renderDocumentLink("PMSB", protocolo.pmsbUrl),
          ],
          [
            "Planta Planialtimétrica",
            renderDocumentLink(
              "Planta Planialtimétrica",
              protocolo.plantaPlanialtUrl
            ),
          ],
          [
            "Planta com Arruamento",
            renderDocumentLink(
              "Planta com Arruamento",
              protocolo.plantaArruamentoUrl
            ),
          ],
          [
            "Cadastro das Redes Existentes",
            renderDocumentLink(
              "Cadastro das Redes Existentes",
              protocolo.cadastroRedesUrl
            ),
          ],
          [
            "Planta As Built do Sistema/ Rede Coletora",
            renderDocumentLink("Planta As Built", protocolo.plantaAsBuiltUrl),
          ],
          ["Diagnóstico Situacional", protocolo.diagnosticoSituacao],
          [
            "Fotocópia da Escritura do Terreno",
            renderDocumentLink(
              "Fotocópia da Escritura do Terreno",
              protocolo.escrituraTerrenoUrl
            ),
          ],
          [
            "Mapa de Localização da Área do Empreendimento",
            renderDocumentLink(
              "Mapa de Localização",
              protocolo.mapaLocalizacaoUrl
            ),
          ],
          [
            "Alternativas Locacionais para Implantação da ETE e Emissários",
            renderDocumentLink(
              "Alternativas Locacionais ETE",
              protocolo.alternativasLocacionaisETEUrl
            ),
          ],
          [
            "Caracterização do Manancial Receptor",
            renderDocumentLink(
              "Caracterização do Manancial Receptor",
              protocolo.caracterizacaoManancialReceptorUrl
            ),
          ],
        ])}

      {protocolo.subtipoProjeto === "ampliacaoRedeAgua" &&
        renderSection("Ampliação de Rede de Água", [
          [
            "Planta Planialtimétrica",
            renderDocumentLink(
              "Planta Planialtimétrica",
              protocolo.plantaPlanialtUrl
            ),
          ],
          [
            "Planta Cadastral",
            renderDocumentLink(
              "Planta Cadastral",
              protocolo.plantaCadastralUrl
            ),
          ],
          [
            "Informações sobre o Sistema Existente",
            protocolo.infoSistemaExistente,
          ],
          [
            "Especificação da Rede de Energia",
            protocolo.especificacaoRedeEnergia,
          ],
          ["Coordenadas Geográficas", protocolo.coordenadasGeograficas],
          [
            "Relação de Beneficiários",
            renderDocumentLink(
              "Relação de Beneficiários",
              protocolo.relacaoBeneficiariosUrl
            ),
          ],
          [
            "Planta As Built do Sistema/ Rede de Distribuição",
            renderDocumentLink(
              "Planta As Built",
              protocolo.plantaAsBuiltAguaUrl
            ),
          ],
        ])}

      {protocolo.subtipoProjeto === "estacaoTransbordo" &&
        renderSection(
          "Estação de Transbordo de Resíduos Sólidos Domiciliares",
          [
            [
              "Fotocópia da Escritura do Terreno",
              renderDocumentLink(
                "Fotocópia da Escritura do Terreno",
                protocolo.escrituraTerrenoUrl
              ),
            ],
            [
              "Planta Topográfica conforme NBR 13.133",
              renderDocumentLink(
                "Planta Topográfica",
                protocolo.plantaTopograficaNBRUrl
              ),
            ],
            [
              "Croqui da Área com as Dimensões do Terreno",
              renderDocumentLink("Croqui da Área", protocolo.croquiAreaDWGUrl),
            ],
            [
              "Planta Cadastral Indicando Uso do Solo",
              renderDocumentLink(
                "Planta Cadastral",
                protocolo.plantaCadastralUsoSoloUrl
              ),
            ],
            [
              "Relatório Fotográfico das Áreas",
              renderDocumentLink(
                "Relatório Fotográfico",
                protocolo.relatorioFotograficoUrl
              ),
            ],
            [
              "Coordenadas Geográficas da Área Escolhida e Imagem do Google Earth",
              renderDocumentLink(
                "Coordenadas Geográficas",
                protocolo.coordenadasGeograficasGoogleUrl
              ),
            ],
            [
              "Declaração de Uso e Ocupação do Solo e Água",
              renderDocumentLink(
                "Declaração de Uso e Ocupação do Solo",
                protocolo.declaracaoUsoOcupacaoUrl
              ),
            ],
            [
              "Apresentação de Projetos: Arquitetônico e Hidrossanitário",
              renderDocumentLink(
                "Apresentação de Projetos",
                protocolo.apresentacaoProjetosUrl
              ),
            ],
          ]
        )}

      {protocolo.subtipoProjeto === "sistemaAbastecimentoAgua" &&
        renderSection("Sistema de Abastecimento de Água", [
          [
            "Planta Planialtimétrica",
            renderDocumentLink(
              "Planta Planialtimétrica",
              protocolo.plantaPlanialtUrl
            ),
          ],
          [
            "Planta Cadastral com Diâmetros e Comprimentos das Tubulações",
            renderDocumentLink(
              "Planta Cadastral",
              protocolo.plantaCadastralAguaUrl
            ),
          ],
          [
            "Informações sobre o Sistema Existente",
            protocolo.infoSistemaExistente,
          ],
          [
            "Especificação da Rede de Energia",
            protocolo.especificacaoRedeEnergia,
          ],
          ["Coordenadas Geográficas", protocolo.coordenadasGeograficasAgua],
          [
            "Relação de Beneficiários",
            renderDocumentLink(
              "Relação de Beneficiários",
              protocolo.relacaoBeneficiariosUrl
            ),
          ],
          [
            "Planta As Built do Sistema/ Rede de Distribuição",
            renderDocumentLink(
              "Planta As Built",
              protocolo.plantaAsBuiltAgua2Url
            ),
          ],
        ])}

      {protocolo.subtipoProjeto === "ligacoesDomiciliares" &&
        renderSection("Ligações Domiciliares", [
          [
            "Cadastro das Ligações Domiciliares Ativas",
            renderDocumentLink(
              "Cadastro de Ligações",
              protocolo.cadastroRedesUrl
            ),
          ],
          [
            "Declaração de ISSQN",
            renderDocumentLink(
              "Declaração de ISSQN",
              protocolo.declaracaoISSQNUrl
            ),
          ],
          [
            "Cópia Autenticada da Carteira de Identidade e CPF do Prefeito",
            renderDocumentLink(
              "Cópia Autenticada",
              protocolo.copiaIdentidadePrefeitoUrl
            ),
          ],
          [
            "Comprovante de Abertura de Conta Corrente Específica para o Convênio",
            renderDocumentLink(
              "Comprovante de Abertura de Conta",
              protocolo.comprovanteContaCorrenteUrl
            ),
          ],
          [
            "Certidão Negativa de Débitos – CND junto ao INSS",
            renderDocumentLink(
              "Certidão Negativa INSS",
              protocolo.certidaoNegativaINSSUrl
            ),
          ],
          [
            "Certificado de Regularidade do FGTS",
            renderDocumentLink(
              "Certificado de Regularidade FGTS",
              protocolo.certificadoRegularidadeFGTSUrl
            ),
          ],
          [
            "Certidão Negativa de Débitos da Fazenda Pública Estadual",
            renderDocumentLink(
              "Certidão Negativa Estadual",
              protocolo.certidaoNegativaEstadualUrl
            ),
          ],
          [
            "Certidão Negativa do Tribunal de Contas do Estado",
            renderDocumentLink(
              "Certidão Negativa TCE",
              protocolo.certidaoNegativaTCEUrl
            ),
          ],
        ])}

      {protocolo.subtipoProjeto === "sistemaTratamentoEsgoto" &&
        renderSection("Sistema de Tratamento de Esgoto", [
          [
            "Planta Planialtimétrica",
            renderDocumentLink(
              "Planta Planialtimétrica",
              protocolo.plantaPlanialtUrl
            ),
          ],
          [
            "Planta com Arruamento",
            renderDocumentLink(
              "Planta com Arruamento",
              protocolo.plantaArruamentoUrl
            ),
          ],
          [
            "Cadastro das Redes Existentes",
            renderDocumentLink(
              "Cadastro das Redes Existentes",
              protocolo.cadastroRedesUrl
            ),
          ],
          [
            "Planta As Built do Sistema/ Rede Coletora",
            renderDocumentLink(
              "Planta As Built",
              protocolo.plantaAsBuiltEsgotoUrl
            ),
          ],
          ["Diagnóstico Situacional", protocolo.diagnosticoSituacaoEsgoto],
          [
            "Fotocópia da Escritura do Terreno",
            renderDocumentLink(
              "Fotocópia da Escritura do Terreno",
              protocolo.escrituraTerrenoUrl
            ),
          ],
          [
            "Mapa de Localização da Área do Empreendimento",
            renderDocumentLink(
              "Mapa de Localização",
              protocolo.mapaLocalizacaoEsgotoUrl
            ),
          ],
          [
            "Alternativas Locacionais para Implantação da ETE e Emissários",
            renderDocumentLink(
              "Alternativas Locacionais ETE",
              protocolo.alternativasLocacionaisETE2Url
            ),
          ],
          [
            "Caracterização do Manancial Receptor",
            renderDocumentLink(
              "Caracterização do Manancial Receptor",
              protocolo.caracterizacaoManancialReceptor2Url
            ),
          ],
        ])}

      {protocolo.subtipoProjeto === "reservatorioAgua" &&
        renderSection("Reservatório de Água", [
          [
            "Ensaio de Sondagem do Terreno",
            renderDocumentLink(
              "Ensaio de Sondagem",
              protocolo.ensaioSondagemUrl
            ),
          ],
          [
            "Declaração de ISSQN",
            renderDocumentLink(
              "Declaração de ISSQN",
              protocolo.declaracaoISSQNUrl
            ),
          ],
          [
            "Declaração de Melhor Opção Econômica",
            protocolo.declaracaoOpcaoEconomica,
          ],
          [
            "Declaração de Responsabilidade",
            renderDocumentLink(
              "Declaração de Responsabilidade",
              protocolo.declaracaoResponsabilidadeUrl
            ),
          ],
          [
            "QCI (Quadro de Composição do Investimento)",
            renderDocumentLink("QCI", protocolo.qciUrl),
          ],
          [
            "Fotocópia da Escritura do Terreno",
            renderDocumentLink(
              "Fotocópia da Escritura do Terreno",
              protocolo.escrituraTerrenoUrl
            ),
          ],
          [
            "Planta Topográfica conforme NBR 13.133",
            renderDocumentLink(
              "Planta Topográfica",
              protocolo.plantaTopograficaReservatorioUrl
            ),
          ],
          [
            "Coordenadas Geográficas do Terreno",
            protocolo.coordenadasGeograficasReservatorio,
          ],
          [
            "Planta do Município Atualizada",
            renderDocumentLink(
              "Planta do Município",
              protocolo.plantaMunicipioReservatorioUrl
            ),
          ],
          [
            "Croqui do Terreno com as Medidas e Área do Terreno",
            renderDocumentLink(
              "Croqui do Terreno",
              protocolo.croquiTerrenoReservatorioUrl
            ),
          ],
        ])}
    </div>
  );
};

export default AdminSaneamentoView;

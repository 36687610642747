// Path: src\pages\EditUser.js
import React, { useEffect, useState, useContext } from "react";
import axios from "../axiosConfig";
import { useParams, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { Button, Form } from "react-bootstrap";
import { fetchMunicipalities } from "../utils/fetchMunicipalities";
import Navbar1 from "../components/Navbar1";
import Footer1 from "../components/Footer1";

const EditUser = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    jobTitle: "",
    phone: "",
    municipality: "",
    password: "", // Adicionado para que o admin possa alterar a senha
    role: "", // Adicionado para que o admin possa alterar o papel do usuário
  });
  const [municipalities, setMunicipalities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`/api/auth/user/${id}`);
        setFormData({
          fullName: response.data.fullName,
          email: response.data.email,
          jobTitle: response.data.jobTitle,
          phone: response.data.phone,
          municipality: response.data.municipality,
          password: "", // Inicialmente vazio
          role: response.data.role, // Define o valor inicial da role
        });
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    const loadMunicipalities = async () => {
      const municipalities = await fetchMunicipalities();
      setMunicipalities(municipalities);
    };

    if (user && user.role === "admin") {
      fetchUser();
      loadMunicipalities();
    } else {
      navigate("/login");
    }
  }, [id, user, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateData = {
      fullName: formData.fullName,
      email: formData.email,
      jobTitle: formData.jobTitle,
      phone: formData.phone,
      municipality: formData.municipality,
      role: formData.role, // Inclui a role na atualização
    };

    // Se o campo de senha estiver preenchido, ele será enviado
    if (formData.password) {
      updateData.password = formData.password;
    }

    try {
      await axios.put(`/api/auth/user/${id}`, updateData);
      alert("Usuário atualizado com sucesso");
      navigate("/user-management");
    } catch (error) {
      console.error("Erro ao atualizar o usuário:", error);
    }
  };

  const handleInactivate = async () => {
    try {
      await axios.put(`/api/auth/user/${id}/inactivate`);
      alert("Usuário inativado com sucesso");
      navigate("/user-management");
    } catch (error) {
      console.error("Error inactivating user:", error);
    }
  };

  return (
    <>
      <Navbar1 />
      <div className="container mt-5">
        <h2>Edit User</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Municipality</Form.Label>
            <Form.Control
              as="select"
              name="municipality"
              value={formData.municipality}
              onChange={handleChange}
            >
              <option value="">Selecione o Município</option>
              {municipalities.map((municipio) => (
                <option key={municipio} value={municipio}>
                  {municipio}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nova Senha</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Deixe vazio se não for alterar"
              value={formData.password}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Função (Role)</Form.Label>
            <Form.Control
              as="select"
              name="role"
              value={formData.role}
              onChange={handleChange}
            >
              <option value="user">Usuário</option>
              <option value="admin">Administrador</option>
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit">
            Save
          </Button>
          <Button variant="danger" onClick={handleInactivate} className="ms-3">
            Inativar Usuário
          </Button>
        </Form>
      </div>
      <Footer1 />
    </>
  );
};

export default EditUser;

// Path: src\components\Footer1.js
import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import packageJson from "../../package.json";

const Footer1 = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-column">
          <h5>Contato</h5>
          <ul>
            <li>
              <a
                href="tel:+556521231222"
                target="_blank"
                rel="noopener noreferrer"
              >
                (65) 2123-1222
              </a>
            </li>
            <li>
              <a
                href="mailto:centraldeprojetosamm@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                centraldeprojetosamm@gmail.com
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h5>Links Úteis</h5>
          <ul>
            <li>
              <a
                href="https://www.amm.org.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                AMM
              </a>
            </li>
            <li>
              <a
                href="https://www.sinfra.mt.gov.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                SINFRA
              </a>
            </li>
            <li>
              <a
                href="https://github.com/olucasrossetti/protocoloamm/blob/7fe57e38f11181294ecdf0897dbb8d3eb5df09c5/README.md"
                target="_blank"
                rel="noopener noreferrer"
              >
                Manual de Uso
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column social-links">
          <h5>Siga a AMM</h5>
          <a
            href="https://www.facebook.com/amm.matogrosso"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/amm.mt/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
      <div
        className="version-info"
        style={{ textAlign: "left", marginTop: "10px" }}
      >
        <p>Versão do App: {packageJson.version}</p>
      </div>
    </footer>
  );
};

export default Footer1;

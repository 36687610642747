// Path: src\utils\fetchMunicipalities.js
export const fetchMunicipalities = async () => {
  try {
    const response = await fetch(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados/MT/municipios"
    );
    const data = await response.json();
    // Mapeia os nomes dos municípios e adiciona "AMM" ao array
    const municipalities = data
      .map((municipio) => municipio.nome)
      .sort((a, b) => a.localeCompare(b));

    // Adiciona a opção "AMM" no início
    municipalities.unshift("AMM"); // Adiciona no início

    return municipalities;
  } catch (error) {
    console.error("Erro ao carregar os municípios:", error);
    return [];
  }
};

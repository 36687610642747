// Path: src\pages\ForgotPassword.js
import React, { useState } from "react";
import { Button, Alert, Form } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import AuthLayout from "../components/AuthLayout";
import axios from "../axiosConfig";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Por favor, insira seu email")
      .email("Por favor, insira um email válido"),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    console.log("Submit event triggered");
    try {
      const response = await axios.post("/api/auth/password/forgot-password", {
        email: data.email,
      });
      console.log("Response received:", response.data);
      setSuccessMessage(response.data.message);
    } catch (err) {
      console.error("Error in Axios request:", err);
      setError(
        err.response?.data?.message || "Erro ao solicitar redefinição de senha"
      );
    }
  };

  return (
    <AuthLayout
      hasSlider
      bottomLinks={
        <p className="text-muted">
          Lembrou sua senha?{" "}
          <Link to="/login" className="text-primary fw-semibold ms-1">
            Login
          </Link>
        </p>
      }
    >
      <h6 className="h5 mb-0 mt-3">Esqueceu sua senha?</h6>
      <p className="text-muted mt-1 mb-4">
        Digite seu email para receber o link de redefinição de senha.
      </p>

      {error && (
        <Alert variant="danger" className="mb-3">
          {error}
        </Alert>
      )}

      {successMessage && (
        <Alert variant="success" className="mb-3">
          {successMessage}
        </Alert>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => <Form.Control type="email" {...field} />}
          />
        </Form.Group>

        <div className="mb-0 text-center d-grid">
          <Button type="submit">Enviar link de redefinição</Button>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default ForgotPassword;

// Path: src\pages\UploadTestPage.js
import React, { useState } from "react";
import axios from "axios";

const UploadTestPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadStatus("Por favor, selecione um arquivo primeiro.");
      return;
    }

    try {
      // Requisita a URL temporária para upload
      const response = await axios.get(
        "/api/upload-teste/generate-presigned-url",
        {
          params: {
            fileName: selectedFile.name,
            fileType: selectedFile.type,
          },
        }
      );

      const { uploadUrl, fileUrl } = response.data;

      // Verifique se a URL de upload foi retornada corretamente
      if (!uploadUrl) {
        throw new Error("Erro ao obter a URL de upload.");
      }

      // Faz upload direto para o S3
      await axios.put(uploadUrl, selectedFile, {
        headers: {
          "Content-Type": selectedFile.type,
        },
      });

      setUploadStatus("Upload realizado com sucesso!");
      setFileUrl(fileUrl); // Define a URL para o arquivo carregado
    } catch (error) {
      console.error("Erro durante o upload:", error);
      setUploadStatus("Erro ao realizar o upload: " + error.message);
    }
  };

  return (
    <div>
      <h1>Upload Direto para S3</h1>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      {uploadStatus && <p>{uploadStatus}</p>}
      {fileUrl && (
        <div>
          <p>Arquivo disponível em:</p>
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            {fileUrl}
          </a>
        </div>
      )}
    </div>
  );
};

export default UploadTestPage;

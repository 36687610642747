// Path: src\components\AmbientalCivilForm.js
import React, { useState } from "react";
import { Form, Button, Alert, ProgressBar } from "react-bootstrap";
import axios from "../axiosConfig";
import { useNavigate } from "react-router-dom";

const AmbientalCivilForm = () => {
  const [step, setStep] = useState(1);
  const [subtipoProjeto, setSubtipoProjeto] = useState("");
  const [formData, setFormData] = useState({
    nomeObjeto: "",
    oficioSolicitacao: null,
    projetoArquitetonico: null,
    memorialEconomicoSanitario: null,
    fluxogramaProcesso: null,
    projetoHidrossanitarioConstrucao: null,
    memorialHidrossanitario: null,
    declaracaoSUSAF: null,
    procuracaoLicenciamento: null,
    documentosPessoais: null,
    comprovanteEndereco: null,
    comprovantePagamentoART: null,
    publicacoesPeriodicos: null,
    documentoAreaAeroporto: null,
    licencaJazida: null,
    declaracaoUsoOcupacao: null,
    comprovanteAgua: null,
    projetosApresentados: null,
    fonteAbastecimento: "",
    produtosProduzidos: "",
    descricaoResiduos: "",
    numeroFuncionarios: "",
    horarioFuncionamento: "",
    jornadaTrabalho: "",
    tratamentoEsgotos: "",
    tipoSepultamento: "",
    origemRecurso: "",
    codigoObra: "",
    enderecoObra: "",
    nomenclaturaObjeto: "",
    coletaTransporteDestinoResiduos: "",
    informacoesBase: "",
    coordenadas: "",
    estudoHidrogeologico: null,
    projetosEngenhariaLoteamento: null,
    documentosAreaLoteamento: null,
  });

  const [error, setError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0); // Progresso de upload
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleNext = () => {
    if (step === 1 && subtipoProjeto === "") {
      setError("Por favor, selecione um subtipo de projeto.");
    } else {
      setError("");
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Ativa o estado de envio

    try {
      const data = new FormData();
      data.append("tipo", "Ambiental Civil");
      data.append("subtipoProjeto", subtipoProjeto);
      for (const key in formData) {
        if (formData[key] !== null && formData[key] !== "") {
          data.append(key, formData[key]);
        }
      }

      const response = await axios.post(
        "/api/protocolos/ambiental-civil",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.floor((loaded * 100) / total);
            setUploadProgress(percentCompleted); // Atualiza o progresso de upload
          },
        }
      );

      if (
        response.data &&
        response.data.protocolo &&
        response.data.protocolo._id
      ) {
        const createdProtocoloId = response.data.protocolo._id;
        navigate(`/protocolo/${createdProtocoloId}`);
      } else {
        throw new Error("ID do protocolo não foi retornado");
      }
    } catch (error) {
      console.error("Erro ao criar protocolo:", error);
      setError("Erro ao criar protocolo. Por favor, tente novamente.");
    } finally {
      setIsSubmitting(false); // Desativa o estado de envio
    }
  };

  const renderTipoProjetoStep = () => (
    <>
      <h4>Seleção do Subtipo de Projeto</h4>
      <Form.Group className="mb-3">
        <Form.Label>Subtipo de Projeto</Form.Label>
        <Form.Control
          as="select"
          name="subtipoProjeto"
          value={subtipoProjeto}
          onChange={(e) => setSubtipoProjeto(e.target.value)}
          required
        >
          <option value="">Selecione o subtipo de projeto</option>
          <option value="agroindustria">Agroindústria</option>
          <option value="aeroporto">Aeroporto</option>
          <option value="cemiterio">Cemitério</option>
          <option value="construcaoNovaReformaAmpliacao">
            Construção Nova, Reforma e Ampliação
          </option>
          <option value="estacaoTransbordo">Estação de Transbordo</option>
          <option value="loteamento">Loteamento</option>
        </Form.Control>
      </Form.Group>
    </>
  );

  const renderDadosGerais = () => (
    <>
      <h4>Dados Gerais</h4>
      <Form.Group className="mb-3">
        <Form.Label>Nome da Solicitação (Objeto)</Form.Label>
        <Form.Control
          type="text"
          name="nomeObjeto"
          value={formData.nomeObjeto}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Ofício da Solicitação (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="oficioSolicitacao"
          onChange={handleChange}
          required
        />
      </Form.Group>
    </>
  );

  // Renderizações para as etapas específicas
  const renderAgroindustriaStep = () => (
    <>
      <h4>Agroindústria</h4>
      <Form.Group className="mb-3">
        <Form.Label>Projeto Arquitetônico (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="projetoArquitetonico"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Memorial Econômico e Sanitário</Form.Label>
        <Form.Control
          type="file"
          name="memorialEconomicoSanitario"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Fonte de Abastecimento</Form.Label>
        <Form.Control
          type="text"
          name="fonteAbastecimento"
          value={formData.fonteAbastecimento}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Produtos Produzidos e Quantidade por Dia</Form.Label>
        <Form.Control
          type="text"
          name="produtosProduzidos"
          value={formData.produtosProduzidos}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Descrição dos Resíduos e Destinação</Form.Label>
        <Form.Control
          type="text"
          name="descricaoResiduos"
          value={formData.descricaoResiduos}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Fluxograma do Processo de Produção (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="fluxogramaProcesso"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Número de Funcionários</Form.Label>
        <Form.Control
          type="text"
          name="numeroFuncionarios"
          value={formData.numeroFuncionarios}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Horário de Funcionamento</Form.Label>
        <Form.Control
          type="text"
          name="horarioFuncionamento"
          value={formData.horarioFuncionamento}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Jornada de Trabalho</Form.Label>
        <Form.Control
          type="text"
          name="jornadaTrabalho"
          value={formData.jornadaTrabalho}
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderAeroportoStep = () => (
    <>
      <h4>Aeroporto</h4>
      <Form.Group className="mb-3">
        <Form.Label>Licença da Jazida (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="licencaJazida"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Documento da Área (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="documentoAreaAeroporto"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Publicações em Periódicos (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="publicacoesPeriodicosAeroporto"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Declaração de Uso e Ocupação do Solo</Form.Label>
        <Form.Control
          type="file"
          name="declaracaoUsoOcupacao"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Comprovante de Água</Form.Label>
        <Form.Control
          type="file"
          name="comprovanteAgua"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Projetos Apresentados (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="projetosApresentados"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderCemiterioStep = () => (
    <>
      <h4>Cemitério</h4>
      <Form.Group className="mb-3">
        <Form.Label>Projeto de Localização (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="projetoLocalizacao"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Localização dos Túmulos (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="localizacaoTumulos"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Tratamento de Esgotos e Destinação dos Efluentes
        </Form.Label>
        <Form.Control
          type="text"
          name="tratamentoEsgotos"
          value={formData.tratamentoEsgotos}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Tipo de Sepultamento</Form.Label>
        <Form.Control
          as="select"
          name="tipoSepultamento"
          value={formData.tipoSepultamento}
          onChange={handleChange}
        >
          <option value="horizontal">Cemitério Horizontal</option>
          <option value="vertical">Cemitério Vertical</option>
          <option value="ambos">Ambos</option>
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Estudo Geológico (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="estudoGeologico"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Layout Básico (PDF)</Form.Label>
        <Form.Control type="file" name="layoutBasico" onChange={handleChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Projetos de Engenharia (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="projetosEngenharia"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderConstrucaoStep = () => (
    <>
      <h4>Construção Nova, Reforma e Ampliação</h4>
      <Form.Group className="mb-3">
        <Form.Label>Fotocópia da Escritura (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="fotocopiaEscritura"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Programa de Necessidades (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="programaNecessidades"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Planta Topográfica (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="plantaTopografica"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Origem do Recurso</Form.Label>
        <Form.Control
          type="text"
          name="origemRecurso"
          value={formData.origemRecurso}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Planta do Município (DWG)</Form.Label>
        <Form.Control
          type="file"
          name="plantaMunicipioConstrucao"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Código da Obra (se houver)</Form.Label>
        <Form.Control
          type="text"
          name="codigoObra"
          value={formData.codigoObra}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Endereço da Obra</Form.Label>
        <Form.Control
          type="text"
          name="enderecoObra"
          value={formData.enderecoObra}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Nomenclatura do Objeto</Form.Label>
        <Form.Control
          type="text"
          name="nomenclaturaObjeto"
          value={formData.nomenclaturaObjeto}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Projeto Hidrossanitário (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="projetoHidrossanitarioConstrucao"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Projeto Elétrico (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="projetoEletrico"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Projeto Estrutural (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="projetoEstrutural"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Orçamento (PDF)</Form.Label>
        <Form.Control type="file" name="orcamento" onChange={handleChange} />
      </Form.Group>
    </>
  );

  const renderEstacaoStep = () => (
    <>
      <h4>Estação de Transbordo</h4>
      <Form.Group className="mb-3">
        <Form.Label>Área a 500 metros de Residências (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="distanciaResidencias"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Coleta, Transporte e Destinação dos Resíduos</Form.Label>
        <Form.Control
          type="text"
          name="coletaTransporteDestinoResiduos"
          value={formData.coletaTransporteDestinoResiduos}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Coordenadas Geográficas (GMS)</Form.Label>
        <Form.Control type="text" name="coordenadas" onChange={handleChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Publicação do Pedido de Outorga (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="publicacaoOutorga"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Projetos Apresentados (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="projetosApresentadosEstacao"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderLoteamentotep = () => (
    <>
      <h4>Loteamento</h4>
      <Form.Group className="mb-3">
        <Form.Label>Informações Base (Tamanho, Lotes, etc.)</Form.Label>
        <Form.Control
          type="text"
          name="informacoesBase"
          value={formData.informacoesBase}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Estudo Hidrogeológico (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="estudoHidrogeologico"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Projetos de Engenharia (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="projetosEngenhariaLoteamento"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Declaração de Uso e Ocupação do Solo (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="declaracaoUsoOcupacaoLoteamento"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Documentos da Área (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="documentosAreaLoteamento"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  // Função para renderizar as etapas conforme o subtipo de projeto selecionado
  const renderSteps = () => {
    switch (subtipoProjeto) {
      case "agroindustria":
        return renderAgroindustriaStep();
      case "aeroporto":
        return renderAeroportoStep();
      case "cemiterio":
        return renderCemiterioStep();
      case "construcaoNovaReformaAmpliacao":
        return renderConstrucaoStep();
      case "estacaoTransbordo":
        return renderEstacaoStep();
      case "loteamento":
        return renderLoteamentotep();
      default:
        return null;
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      {uploadProgress > 0 && uploadProgress < 100 && (
        <ProgressBar
          now={uploadProgress}
          label={`${uploadProgress}%`}
          className="mb-3"
        />
      )}

      {step === 1 && renderTipoProjetoStep()}
      {step === 2 && renderDadosGerais()}
      {step >= 3 && renderSteps()}

      <div className="d-flex justify-content-between mt-4">
        {step > 1 && (
          <Button variant="secondary" onClick={handlePrevious}>
            Voltar
          </Button>
        )}
        {step < 3 && (
          <Button
            variant="primary"
            onClick={handleNext}
            disabled={!subtipoProjeto && step === 1}
          >
            Próxima Fase
          </Button>
        )}
        {step === 3 && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Enviando..." : "Enviar Solicitação"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default AmbientalCivilForm;

// Path: src\pages\AdminDashboard.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar1 from "../components/Navbar1";
import { FaCheckCircle, FaTimesCircle, FaHourglassHalf } from "react-icons/fa";
import axios from "../axiosConfig";
import { fetchMunicipalities } from "../utils/fetchMunicipalities";
import Footer1 from "../components/Footer1";

const AdminDashboard = () => {
  const [protocolos, setProtocolos] = useState({
    pendentes: [],
    aprovados: [],
    rejeitados: [],
  });
  const [municipios, setMunicipios] = useState([]);
  const [filtros, setFiltros] = useState({
    municipio: "",
    ano: "",
    numero: "",
    status: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchProtocolos();
    loadMunicipios();
  }, []);

  const fetchProtocolos = async () => {
    try {
      const [
        protocolosResponse,
        pavimentacaoResponse,
        drenagemResponse,
        hidrologicoResponse,
        topograficoResponse,
        estradaVicinalResponse,
        obraDeArteEspecialResponse,
        ambientalCivilResponse,
        ambientalInfraResponse,
        saneamentoResponse,
        loteamentoResponse,
      ] = await Promise.all([
        axios.get("/api/protocolos"),
        axios.get("/api/pavimentacao-urbana"),
        axios.get("/api/drenagem-urbana"),
        axios.get("/api/estudo-hidrologico"),
        axios.get("/api/levantamento-topografico"),
        axios.get("/api/estrada-vicinal"),
        axios.get("/api/obra-de-arte-especial"),
        axios.get("/api/ambiental-civil"),
        axios.get("/api/ambiental-infra"),
        axios.get("/api/saneamento"),
        axios.get("/api/loteamento"), // Nova rota para Loteamento
      ]);

      const data = [
        ...(protocolosResponse.data || []),
        ...(pavimentacaoResponse.data || []),
        ...(drenagemResponse.data || []),
        ...(hidrologicoResponse.data || []),
        ...(topograficoResponse.data || []),
        ...(estradaVicinalResponse.data || []),
        ...(obraDeArteEspecialResponse.data || []),
        ...(ambientalCivilResponse.data || []),
        ...(ambientalInfraResponse.data || []),
        ...(saneamentoResponse.data || []),
        ...(loteamentoResponse.data || []), // Adiciona dados de Loteamento
      ];

      setProtocolos({
        pendentes: data.filter((p) => p.status === "pendente"),
        aprovados: data.filter((p) => p.status === "aprovado"),
        rejeitados: data.filter((p) => p.status === "rejeitado"),
      });
    } catch (error) {
      console.error("Erro ao buscar protocolos:", error);
    }
  };

  const loadMunicipios = async () => {
    const municipios = await fetchMunicipalities();
    setMunicipios(municipios);
  };

  const handleViewDetails = (protocoloId) => {
    navigate(`/admin/protocolo/${protocoloId}`);
  };

  const handleFilterChange = (e) => {
    setFiltros({
      ...filtros,
      [e.target.name]: e.target.value,
    });
  };

  const applyFilters = (protocolo) => {
    const { municipio, protocoloInput, status } = filtros;
    const protocoloValido = protocolo.numeroProtocolo || "";
    const matchesProtocolo =
      !protocoloInput || protocoloValido.includes(protocoloInput);
    const matchesMunicipio =
      !municipio || protocolo.usuario.municipality.includes(municipio);
    const matchesStatus = !status || protocolo.status === status.toLowerCase();
    return matchesMunicipio && matchesProtocolo && matchesStatus;
  };

  const renderProtocolos = (protocolos, title, showActions = false) => (
    <div className="card mb-4">
      <div
        className={`card-header ${
          title === "Pendentes"
            ? "bg-warning"
            : title === "Aprovados"
            ? "bg-success"
            : "bg-danger"
        } text-white d-flex align-items-center`}
      >
        {title === "Pendentes" ? (
          <FaHourglassHalf className="me-2" />
        ) : title === "Aprovados" ? (
          <FaCheckCircle className="me-2" />
        ) : (
          <FaTimesCircle className="me-2" />
        )}
        <h5 className="mb-0">{title}</h5>
      </div>
      <div className="card-body">
        {protocolos.filter(applyFilters).length > 0 ? (
          <div className="table-responsive">
            <table className="table table-hover table-shared">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Município</th>
                  <th>Tipo</th>
                  <th>Nome do Objeto</th>
                  <th>Data de Abertura</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {protocolos.filter(applyFilters).map((protocolo, index) => (
                  <tr key={protocolo._id}>
                    <td>{index + 1}</td>
                    <td>{protocolo.usuario.municipality}</td>
                    <td>{protocolo.tipo}</td>
                    <td>{protocolo.nomeObjeto}</td>
                    <td>
                      {new Date(protocolo.createdAt).toLocaleDateString()}
                    </td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm me-2"
                        onClick={() => handleViewDetails(protocolo._id)}
                      >
                        Ver Detalhes
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="mb-0">
            Não há protocolos {title.toLowerCase()} no momento.
          </p>
        )}
      </div>
    </div>
  );

  return (
    <>
      <Navbar1 />
      <div className="container mt-4">
        <h2 className="mb-4">Painel do Administrador</h2>

        <div className="row mb-4">
          <div className="col-md-4">
            <label>Município</label>
            <input
              type="text"
              className="form-control"
              name="municipio"
              value={filtros.municipio}
              onChange={handleFilterChange}
              list="municipios-list"
              placeholder="Digite para filtrar por município"
            />
            <datalist id="municipios-list">
              {municipios.map((municipio, index) => (
                <option key={index} value={municipio} />
              ))}
            </datalist>
          </div>
          <div className="col-md-4">
            <label>Número do Protocolo</label>
            <input
              type="text"
              className="form-control"
              name="protocoloInput"
              value={filtros.protocoloInput}
              onChange={handleFilterChange}
              placeholder="Ex: AMM20240004"
            />
          </div>
          <div className="col-md-4">
            <label>Status</label>
            <select
              className="form-control"
              name="status"
              value={filtros.status}
              onChange={handleFilterChange}
            >
              <option value="">Todos</option>
              <option value="pendente">Pendente</option>
              <option value="aprovado">Aprovado</option>
              <option value="rejeitado">Rejeitado</option>
            </select>
          </div>
        </div>

        {renderProtocolos(protocolos.pendentes, "Pendentes", true)}
        {renderProtocolos(protocolos.aprovados, "Aprovados", false)}
        {renderProtocolos(protocolos.rejeitados, "Rejeitados", false)}
      </div>
      <Footer1 />
    </>
  );
};

export default AdminDashboard;

// Path: src\pages\SelectRequestType.js
import React, { useState } from "react";
import { Card, Row, Col, Modal, Button } from "react-bootstrap";
import Navbar1 from "../components/Navbar1";
import Footer1 from "../components/Footer1";
import ConstrucaoNovaForm from "../components/ConstrucaoNovaForm";
import ReformaAmpliacaoForm from "../components/ReformaAmpliacaoForm";
import LevantamentoTopograficoForm from "../components/LevantamentoTopograficoForm";
import LoteamentoForm from "../components/LoteamentoForm";
import {
  FaSchool,
  FaHospital,
  FaBuilding,
  FaRoad,
  FaRecycle,
  FaMapMarkedAlt,
  FaTree,
} from "react-icons/fa"; // Novo ícone
import { Link, useNavigate } from "react-router-dom";

const SelectRequestType = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const navigate = useNavigate();

  const handleSelectType = (type) => {
    if (
      type === "construcao-nova" ||
      type === "reforma-ampliacao" ||
      type === "levantamento-topografico" ||
      type === "loteamento"
    ) {
      setModalType(type); // Define o tipo de modal a ser aberto
      setShowModal(true); // Abre o modal
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType(""); // Reseta o tipo de modal
  };

  const renderModalContent = () => {
    switch (modalType) {
      case "construcao-nova":
        return <ConstrucaoNovaForm onSuccess={handleCloseModal} />;
      case "reforma-ampliacao":
        return <ReformaAmpliacaoForm onSuccess={handleCloseModal} />;
      case "levantamento-topografico":
        return <LevantamentoTopograficoForm onSuccess={handleCloseModal} />;
      case "loteamento":
        return <LoteamentoForm onSuccess={handleCloseModal} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar1 />
      <div className="container mt-5">
        <h2>Criar Solicitação de Projeto</h2>
        <p>Selecione o tipo de solicitação de projeto:</p>
        <Row>
          <Col md={6} lg={4} className="mb-4">
            <Card
              onClick={() => handleSelectType("construcao-nova")}
              className="text-center card-custom"
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <Card.Title className="mb-3">
                  Civil - Construção Nova
                </Card.Title>
                <Card.Text className="mb-3">
                  Projetos para construção de novas edificações. Exemplos:
                </Card.Text>
                <ul className="list-unstyled text-left pl-3">
                  <li>
                    <FaSchool className="mr-2" /> Escolas
                  </li>
                  <li>
                    <FaHospital className="mr-2" /> Hospitais
                  </li>
                  <li>
                    <FaBuilding className="mr-2" /> Prédios Administrativos
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mb-4">
            <Card
              onClick={() => handleSelectType("reforma-ampliacao")}
              className="text-center card-custom"
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <Card.Title className="mb-3">
                  Civil - Reforma e Ampliação
                </Card.Title>
                <Card.Text className="mb-3">
                  Projetos para reforma e ampliação de edificações existentes.
                  Exemplos:
                </Card.Text>
                <ul className="list-unstyled text-left pl-3">
                  <li>
                    <FaSchool className="mr-2" /> Reforma de escolas
                  </li>
                  <li>
                    <FaHospital className="mr-2" /> Ampliação de unidades de
                    saúde
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4} className="mb-4">
            <Link
              to="/select-infrastructure-type"
              style={{ textDecoration: "none" }}
            >
              <Card
                className="text-center card-custom"
                style={{ cursor: "pointer" }}
              >
                <Card.Body>
                  <Card.Title className="mb-3">Infraestrutura</Card.Title>
                  <Card.Text className="mb-3">
                  Projetos completos de infraestrutura para áreas urbanas e rurais. Exemplos:
                  </Card.Text>
                  <ul className="list-unstyled text-left pl-3">
                    <li>
                      <FaRoad className="mr-2" /> Pavimentação de ruas
                    </li>
                    <li>
                      <FaRoad className="mr-2" /> Sistemas de drenagem
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Link>
          </Col>

          <Col md={6} lg={4} className="mb-4">
            <Card
              onClick={() => handleSelectType("levantamento-topografico")}
              className="text-center card-custom"
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <Card.Title className="mb-3">
                  Levantamento Topográfico
                </Card.Title>
                <Card.Text className="mb-3">
                  Solicitação de levantamento topográfico para projetos.
                  Exemplos:
                </Card.Text>
                <ul className="list-unstyled text-left pl-3">
                  <li>
                    <FaMapMarkedAlt className="mr-2" /> Levantamento de áreas
                    urbanas
                  </li>
                  <li>
                    <FaMapMarkedAlt className="mr-2" /> Levantamento de áreas
                    rurais
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4} className="mb-4">
            <Card
              onClick={() => (window.location.href = "/select-ambiental-type")}
              className="text-center card-custom"
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <Card.Title className="mb-3">Ambiental</Card.Title>
                <Card.Text className="mb-3">
                  Projetos relacionados à preservação e recuperação ambiental.
                  Exemplos:
                </Card.Text>
                <ul className="list-unstyled text-left pl-3">
                  <li>
                    <FaRecycle className="mr-2" /> Licenciamentos
                  </li>
                  <li>
                    <FaRecycle className="mr-2" /> Recuperação de áreas
                    degradadas
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mb-4">
            <Card
              onClick={() => handleSelectType("loteamento")}
              className="text-center card-custom"
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <Card.Title className="mb-3">Loteamento</Card.Title>
                <Card.Text className="mb-3">
                  Projetos para parcelamento do solo e desenvolvimento de
                  loteamento. Exemplos:
                </Card.Text>
                <ul className="list-unstyled text-left pl-3">
                  <li>
                    <FaTree className="mr-2" /> Loteamentos residenciais
                  </li>
                  <li>
                    <FaTree className="mr-2" /> Áreas de expansão urbana
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Button
          variant="secondary"
          onClick={() => navigate(-1)}
          className="mb-4"
        >
          Voltar
        </Button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Criar Nova Requisição -{" "}
            {modalType === "construcao-nova"
              ? "Construção Nova"
              : modalType === "reforma-ampliacao"
              ? "Reforma ou Ampliação"
              : modalType === "levantamento-topografico"
              ? "Levantamento Topográfico"
              : "Loteamento"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderModalContent()}</Modal.Body>
      </Modal>

      <Footer1 />
    </>
  );
};

export default SelectRequestType;

// Path: src\components\DrenagemUrbanaForm.js
import React, { useState } from "react";
import { Form, Button, Alert, ProgressBar } from "react-bootstrap";
import axios from "../axiosConfig";
import { useNavigate } from "react-router-dom";

const DrenagemUrbanaForm = ({ onSuccess }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nomeObjeto: "",
    oficio: null,
    plantaMunicipioDwg: null,
    indicacaoRuasKmz: null,
    plantaTopografica: null,
    dissipadorEnergiaGms: "",
    licencaBotaFora: null,
    localizacaoBotaForaGms: "",
    localizacaoAreal: null,
    cadastroRedeExistenteDwg: null,
    relatorioFotografico: null,
    issqn: null,
  });

  const [error, setError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0); // Estado para o progresso de upload
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Ativa o estado de envio

    try {
      const data = new FormData();
      for (const key in formData) {
        if (formData[key] !== null && formData[key] !== "") {
          data.append(key, formData[key]);
        }
      }

      const response = await axios.post(
        "/api/protocolos/drenagem-urbana",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.floor((loaded * 100) / total);
            setUploadProgress(percentCompleted); // Atualiza o progresso de upload
          },
        }
      );

      if (
        response.data &&
        response.data.protocolo &&
        response.data.protocolo._id
      ) {
        const createdProtocoloId = response.data.protocolo._id;
        navigate(`/protocolo/${createdProtocoloId}`);
      } else {
        throw new Error("ID do protocolo não foi retornado");
      }
    } catch (error) {
      setError("Erro ao criar protocolo. Por favor, tente novamente.");
    } finally {
      setIsSubmitting(false); // Desativa o estado de envio
    }
  };

  const renderCivilStep = () => (
    <>
      <h4>Dados Gerais</h4>
      <Form.Group className="mb-3">
        <Form.Label>Nome da Solicitação (Objeto)</Form.Label>
        <Form.Control
          type="text"
          name="nomeObjeto"
          value={formData.nomeObjeto}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Ofício da Solicitação (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="oficio"
          onChange={handleChange}
          required
        />
      </Form.Group>
    </>
  );

  const renderArquiteturaStep = () => (
    <>
      <h4>Informações da Drenagem Urbana</h4>
      <Form.Group className="mb-3">
        <Form.Label>Planta do Município (DWG)</Form.Label>
        <Form.Control
          type="file"
          name="plantaMunicipioDwg"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Indicação das Ruas (KMZ)</Form.Label>
        <Form.Control
          type="file"
          name="indicacaoRuasKmz"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Planta Topográfica (se não for feito pela AMM)</Form.Label>
        <Form.Control
          type="file"
          name="plantaTopografica"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Localização do Provável Dissipador de Energia GMS (Texto)
        </Form.Label>
        <Form.Control
          type="text"
          name="dissipadorEnergiaGms"
          value={formData.dissipadorEnergiaGms}
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderJazidaBotaForaStep = () => (
    <>
      <h4>Jazida e Bota-Fora</h4>
      <Form.Group className="mb-3">
        <Form.Label>Licença ou Declaração de Bota-Fora</Form.Label>
        <Form.Control
          type="file"
          name="licencaBotaFora"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Localização do Bota-Fora GMS (Texto)</Form.Label>
        <Form.Control
          type="text"
          name="localizacaoBotaForaGms"
          value={formData.localizacaoBotaForaGms}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Localização do Areal</Form.Label>
        <Form.Control
          type="file"
          name="localizacaoAreal"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Cadastro de Rede Existente (DWG)</Form.Label>
        <Form.Control
          type="file"
          name="cadastroRedeExistenteDwg"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderOrcamentoStep = () => (
    <>
      <h4>Informações de Orçamento</h4>
      <Form.Group className="mb-3">
        <Form.Label>Relatório Fotográfico</Form.Label>
        <Form.Control
          type="file"
          name="relatorioFotografico"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Declaração de ISSQN com alíquota e base de cálculo
        </Form.Label>
        <Form.Control type="file" name="issqn" onChange={handleChange} />
      </Form.Group>
    </>
  );

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Exibe a barra de progresso quando o upload começa */}
      {uploadProgress > 0 && uploadProgress < 100 && (
        <ProgressBar
          now={uploadProgress}
          label={`${uploadProgress}%`}
          className="mb-3"
        />
      )}

      {step === 1 && renderCivilStep()}
      {step === 2 && renderArquiteturaStep()}
      {step === 3 && renderJazidaBotaForaStep()}
      {step === 4 && renderOrcamentoStep()}

      <div className="d-flex justify-content-between mt-4">
        {step > 1 && (
          <Button variant="secondary" onClick={handlePrevious}>
            Voltar
          </Button>
        )}
        {step < 4 && (
          <Button variant="primary" onClick={handleNext}>
            Próxima Fase
          </Button>
        )}
        {step === 4 && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Enviando..." : "Enviar Solicitação"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default DrenagemUrbanaForm;

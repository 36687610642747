// Path: src\pages\tipos\AmbientalCivilView.js
import React, { useState } from "react";
import { Table, Card, Button, Badge, Form } from "react-bootstrap";
import axios from "../../axiosConfig";

const AmbientalCivilView = ({ protocolo }) => {
  const [responses, setResponses] = useState({});
  const [fileResponses, setFileResponses] = useState({});
  const [protocoloData, setProtocoloData] = useState(protocolo);
  const [isSubmitting, setIsSubmitting] = useState({}); // Novo estado para rastrear envio

  const handleResponseChange = (issueId, value) => {
    setResponses({
      ...responses,
      [issueId]: value,
    });
  };

  const handleFileChange = (issueId, file) => {
    setFileResponses({
      ...fileResponses,
      [issueId]: file,
    });
  };

  const handleResponseSubmit = async (issueId) => {
    setIsSubmitting((prev) => ({ ...prev, [issueId]: true })); // Ativa o estado de envio
    try {
      const response = responses[issueId];
      const fileResponse = fileResponses[issueId];

      let updatedIssue;

      if (fileResponse) {
        const formData = new FormData();
        formData.append("file", fileResponse);

        const result = await axios.post(
          `/api/ambiental-civil/${protocolo._id}/pendencias/${issueId}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        updatedIssue = result.data.protocolo.pendingIssues.find(
          (issue) => issue._id === issueId
        );
      } else if (response) {
        const result = await axios.put(
          `/api/ambiental-civil/${protocolo._id}/pendencias/${issueId}/responder`,
          {
            response,
          }
        );
        updatedIssue = result.data.protocolo.pendingIssues.find(
          (issue) => issue._id === issueId
        );
      }

      if (updatedIssue) {
        setProtocoloData((prevState) => {
          const updatedPendingIssues = prevState.pendingIssues.map((issue) =>
            issue._id === issueId ? updatedIssue : issue
          );
          return { ...prevState, pendingIssues: updatedPendingIssues };
        });
      }

      setResponses((prev) => ({ ...prev, [issueId]: "" }));
      setFileResponses((prev) => ({ ...prev, [issueId]: null }));
    } catch (error) {
      console.error("Erro ao enviar resposta:", error);
    } finally {
      setIsSubmitting((prev) => ({ ...prev, [issueId]: false })); // Desativa o estado de envio após a conclusão
    }
  };

  const renderStatusTag = (status) => {
    switch (status) {
      case "pendente":
        return <Badge bg="warning">Pendente</Badge>;
      case "respondido":
        return <Badge bg="success">Respondido</Badge>;
      case "aprovado":
        return <Badge bg="primary">Aprovado</Badge>;
      default:
        return null;
    }
  };

  const renderPendingIssues = (fieldName, fieldValue) => {
    const normalizedFieldName = fieldName.trim().toLowerCase();
    const issues = protocoloData.pendingIssues?.filter(
      (issue) => issue.fieldName.trim().toLowerCase() === normalizedFieldName
    );

    const isFileUpload =
      (React.isValidElement(fieldValue) && fieldValue.type === "a") ||
      (typeof fieldValue === "string" &&
        fieldValue === "Arquivo Não informado");

    return issues && issues.length > 0 ? (
      <ul>
        {issues.map((issue, index) => (
          <li key={index}>
            {issue.description} {renderStatusTag(issue.status)}
            {issue.status === "pendente" && (
              <div style={{ marginTop: "10px" }}>
                {isFileUpload ? (
                  <div>
                    <Form.Control
                      type="file"
                      onChange={(e) =>
                        handleFileChange(issue._id, e.target.files[0])
                      }
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                ) : (
                  <Form.Control
                    type="text"
                    placeholder="Responda aqui..."
                    value={responses[issue._id] || ""}
                    onChange={(e) =>
                      handleResponseChange(issue._id, e.target.value)
                    }
                  />
                )}
                <Button
                  onClick={() => handleResponseSubmit(issue._id)}
                  variant="primary"
                  size="sm"
                  style={{ marginTop: "5px" }}
                  disabled={isSubmitting[issue._id]} // Desativa o botão durante o envio
                >
                  {isSubmitting[issue._id] ? "Enviando..." : "Enviar Resposta"}
                </Button>
              </div>
            )}
            {issue.status === "respondido" && issue.response && (
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "5px",
                  color: "#28a745",
                }}
              >
                <strong>Resposta:</strong> {issue.response}
                <br />
                <small>
                  Respondido em: {new Date(issue.responseDate).toLocaleString()}
                </small>
              </div>
            )}
            {issue.status === "respondido" && issue.responseFileUrl && (
              <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                <strong>Arquivo enviado:</strong>{" "}
                <a
                  href={issue.responseFileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {issue.responseFileName || "Visualizar arquivo"}
                </a>
                <br />
                <small>
                  Respondido em: {new Date(issue.responseDate).toLocaleString()}
                </small>
              </div>
            )}
          </li>
        ))}
      </ul>
    ) : (
      ""
    );
  };

  const renderDocumentLink = (label, url) =>
    url ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        Visualizar documento
      </a>
    ) : (
      "Arquivo Não informado"
    );

  const renderSection = (title, fields) => (
    <div className="mb-4">
      <h5 className="section-title">{title}</h5>
      <Table striped bordered hover className="custom-table">
        <thead>
          <tr>
            <th style={{ width: "450px" }}>Campo</th>
            <th>Valor</th>
            <th style={{ width: "400px" }}>Pendências</th>
          </tr>
        </thead>
        <tbody>
          {fields.map(([label, value], index) => (
            <tr key={index}>
              <td>
                <strong>{label}</strong>
              </td>
              <td>{value || "Não informado"}</td>
              <td>{renderPendingIssues(label, value)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "aprovado":
        return "#28a745"; // Verde
      case "pendente":
        return "#ffc107"; // Amarelo
      case "rejeitado":
        return "#dc3545"; // Vermelho
      default:
        return "#6c757d"; // Cinza (para status desconhecidos)
    }
  };

  return (
    <div>
      <Card
        className="mb-4"
        style={{ backgroundColor: "#007bff", color: "white" }}
      >
        <Card.Body>
          <h2 className="texto">
            {protocolo.nomeObjeto} - {protocolo.usuario.municipality}
          </h2>
        </Card.Body>
      </Card>

      <Card
        className="mb-4"
        style={{
          backgroundColor: getStatusColor(protocolo.status),
          color: "white",
          height: "50px",
          lineHeight: "30px",
          padding: "10px 20px",
        }}
      >
        <Card.Body className="p-0 d-flex align-items-center">
          <h4 className="m-0">
            Status:{" "}
            {protocolo.status.charAt(0).toUpperCase() +
              protocolo.status.slice(1)}
          </h4>
        </Card.Body>
      </Card>

      {protocolo.status === "rejeitado" && protocolo.rejectionJustification && (
        <Card
          className="mb-4"
          style={{ backgroundColor: "#dc3545", color: "white" }}
        >
          <Card.Body>
            <h4>Justificativa da Rejeição</h4>
            <p>{protocolo.rejectionJustification}</p>
          </Card.Body>
        </Card>
      )}

      <h2>Detalhes do Protocolo - Ambiental Civil</h2>

      {renderSection("Dados Gerais", [
        ["Número do Protocolo", protocolo.numeroProtocolo],
        ["Nome da Solicitação", protocolo.nomeObjeto],
        ["Tipo de Solicitação", protocolo.subtipoProjeto],
        [
          "Ofício",
          renderDocumentLink("Ofício", protocolo.oficioSolicitacaoUrl),
        ],
        ["Data de Criação", new Date(protocolo.createdAt).toLocaleString()],
        ["Data de Atualização", new Date(protocolo.updatedAt).toLocaleString()],
      ])}

      {renderSection("Dados do Solicitante", [
        ["Nome Completo", protocolo.usuario.fullName],
        ["Email", protocolo.usuario.email],
        ["Cargo", protocolo.usuario.jobTitle],
        ["Telefone", protocolo.usuario.phone],
        ["Município", protocolo.usuario.municipality],
      ])}

      {/* Exibe as seções com base no subtipo do protocolo */}
      {protocolo.subtipoProjeto === "agroindustria" &&
        renderSection("Agroindústria", [
          [
            "Projeto Arquitetônico",
            renderDocumentLink(
              "Projeto Arquitetônico",
              protocolo.projetoArquitetonicoUrl
            ),
          ],
          [
            "Memorial Econômico e Sanitário",
            renderDocumentLink(
              "Memorial Econômico e Sanitário",
              protocolo.memorialEconomicoSanitarioUrl
            ),
          ],
          ["Fonte de Abastecimento", protocolo.fonteAbastecimento],
          ["Produtos Produzidos", protocolo.produtosProduzidos],
          ["Descrição dos Resíduos", protocolo.descricaoResiduos],
          [
            "Fluxograma do Processo de Produção",
            renderDocumentLink(
              "Fluxograma do Processo de Produção",
              protocolo.fluxogramaProcessoUrl
            ),
          ],
          ["Número de Funcionários", protocolo.numeroFuncionarios],
          ["Horário de Funcionamento", protocolo.horarioFuncionamento],
          ["Jornada de Trabalho", protocolo.jornadaTrabalho],
        ])}

      {protocolo.subtipoProjeto === "aeroporto" &&
        renderSection("Aeroporto", [
          [
            "Ofício de Solicitação",
            renderDocumentLink(
              "Ofício de Solicitação",
              protocolo.oficioSolicitacaoUrl
            ),
          ],
          [
            "Licença da Jazida",
            renderDocumentLink("Licença da Jazida", protocolo.licencaJazidaUrl),
          ],
          [
            "Documento da Área",
            renderDocumentLink(
              "Documento da Área",
              protocolo.documentoAreaAeroportoUrl
            ),
          ],
          [
            "Publicações em Periódicos",
            renderDocumentLink(
              "Publicações em Periódicos",
              protocolo.publicacoesPeriodicosAeroportoUrl
            ),
          ],
          [
            "Declaração de Uso e Ocupação do Solo",
            renderDocumentLink(
              "Declaração de Uso e Ocupação do Solo",
              protocolo.declaracaoUsoOcupacaoUrl
            ),
          ],
          [
            "Comprovante de Água",
            renderDocumentLink(
              "Comprovante de Água",
              protocolo.comprovanteAguaUrl
            ),
          ],
          [
            "Projetos Apresentados",
            renderDocumentLink(
              "Projetos Apresentados",
              protocolo.projetosApresentadosUrl
            ),
          ],
        ])}

      {protocolo.subtipoProjeto === "cemiterio" &&
        renderSection("Cemitério", [
          [
            "Projeto de Localização",
            renderDocumentLink(
              "Projeto de Localização",
              protocolo.projetoLocalizacaoUrl
            ),
          ],
          [
            "Localização dos Túmulos",
            renderDocumentLink(
              "Localização dos Túmulos",
              protocolo.localizacaoTumulosUrl
            ),
          ],
          [
            "Tratamento de Esgotos e Destinação dos Efluentes",
            protocolo.tratamentoEsgotos,
          ],
          ["Tipo de Sepultamento", protocolo.tipoSepultamento],
          [
            "Estudo Geológico",
            renderDocumentLink(
              "Estudo Geológico",
              protocolo.estudoGeologicoUrl
            ),
          ],
          [
            "Layout Básico",
            renderDocumentLink("Layout Básico", protocolo.layoutBasicoUrl),
          ],
          [
            "Projetos de Engenharia",
            renderDocumentLink(
              "Projetos de Engenharia",
              protocolo.projetosEngenhariaUrl
            ),
          ],
        ])}

      {protocolo.subtipoProjeto === "construcaoNovaReformaAmpliacao" &&
        renderSection("Construção Nova, Reforma e Ampliação", [
          [
            "Fotocópia da Escritura",
            renderDocumentLink(
              "Fotocópia da Escritura",
              protocolo.fotocopiaEscrituraUrl
            ),
          ],
          [
            "Programa de Necessidades",
            renderDocumentLink(
              "Programa de Necessidades",
              protocolo.programaNecessidadesUrl
            ),
          ],
          [
            "Planta Topográfica",
            renderDocumentLink(
              "Planta Topográfica",
              protocolo.plantaTopograficaUrl
            ),
          ],
          ["Origem do Recurso", protocolo.origemRecurso],
          [
            "Planta do Município",
            renderDocumentLink(
              "Planta do Município",
              protocolo.plantaMunicipioConstrucaoUrl
            ),
          ],
          ["Código da Obra", protocolo.codigoObra],
          ["Endereço da Obra", protocolo.enderecoObra],
          ["Nomenclatura do Objeto", protocolo.nomenclaturaObjeto],
          [
            "Projeto Hidrossanitário",
            renderDocumentLink(
              "Projeto Hidrossanitário",
              protocolo.projetoHidrossanitarioConstrucaoUrl
            ),
          ],
          [
            "Projeto Elétrico",
            renderDocumentLink(
              "Projeto Elétrico",
              protocolo.projetoEletricoUrl
            ),
          ],
          [
            "Projeto Estrutural",
            renderDocumentLink(
              "Projeto Estrutural",
              protocolo.projetoEstruturalUrl
            ),
          ],
          [
            "Orçamento",
            renderDocumentLink("Orçamento", protocolo.orcamentoUrl),
          ],
        ])}

      {protocolo.subtipoProjeto === "estacaoTransbordo" &&
        renderSection("Estação de Transbordo", [
          [
            "Área a 500 metros de Residências",
            renderDocumentLink(
              "Área a 500 metros de Residências",
              protocolo.distanciaResidenciasUrl
            ),
          ],
          [
            "Coleta, Transporte e Destinação dos Resíduos",
            protocolo.coletaTransporteDestinoResiduos,
          ],
          ["Coordenadas Geográficas (GMS)", protocolo.coordenadas],
          [
            "Publicação do Pedido de Outorga",
            renderDocumentLink(
              "Publicação do Pedido de Outorga",
              protocolo.publicacaoOutorgaUrl
            ),
          ],
          [
            "Projetos Apresentados",
            renderDocumentLink(
              "Projetos Apresentados",
              protocolo.projetosApresentadosEstacaoUrl
            ),
          ],
        ])}

      {protocolo.subtipoProjeto === "loteamento" &&
        renderSection("Loteamento", [
          ["Informações Base", protocolo.informacoesBase],
          [
            "Estudo Hidrogeológico",
            renderDocumentLink(
              "Estudo Hidrogeológico",
              protocolo.estudoHidrogeologicoUrl
            ),
          ],
          [
            "Projetos de Engenharia",
            renderDocumentLink(
              "Projetos de Engenharia",
              protocolo.projetosEngenhariaLoteamentoUrl
            ),
          ],
          [
            "Declaração de Uso e Ocupação do Solo",
            renderDocumentLink(
              "Declaração de Uso e Ocupação do Solo",
              protocolo.declaracaoUsoOcupacaoLoteamentoUrl
            ),
          ],
          [
            "Documentos da Área",
            renderDocumentLink(
              "Documentos da Área",
              protocolo.documentosAreaLoteamentoUrl
            ),
          ],
        ])}
    </div>
  );
};

export default AmbientalCivilView;

// Path: src\components\AmbientalInfraForm.js
import React, { useState } from "react";
import { Form, Button, Alert, ProgressBar } from "react-bootstrap";
import axios from "../axiosConfig";
import { useNavigate } from "react-router-dom";

const AmbientalInfraForm = () => {
  const [step, setStep] = useState(1);
  const [subtipoProjeto, setSubtipoProjeto] = useState("");
  const [formData, setFormData] = useState({
    nomeObjeto: "",
    oficioSolicitacao: null,
    documentosPrefeito: null,
    areaProposta: null,
    relatorioFotografico: null,
    documentoArea: null,
    imagemPoligono: null,
    procuracaoPrefeito: null,
    declaracaoUsoSolo: null,
    pagamentoArt: null,
    comprovanteAgua: null,
    artEngenheiro: null,
    publicacaoOutorga: null,
    fluxogramaSistemaTratamento: null,
    boletimAnaliseEfluente: null,
    identificacaoCorpoHidrico: "",
    coordenadasPontoCaptacao: "",
    identificacaoSistemaCaptacao: "",
    dadosTecnicosCaptacao: "",
  });

  const [error, setError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0); // Progresso de upload
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleNext = () => {
    if (step === 1 && subtipoProjeto === "") {
      setError("Por favor, selecione um subtipo de projeto.");
    } else {
      setError("");
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Ativa o estado de envio

    try {
      const data = new FormData();
      data.append("tipo", "Ambiental Infraestrutura");
      data.append("subtipoProjeto", subtipoProjeto);
      for (const key in formData) {
        if (formData[key] !== null && formData[key] !== "") {
          data.append(key, formData[key]);
        }
      }

      const response = await axios.post(
        "/api/protocolos/ambiental-infra",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.floor((loaded * 100) / total);
            setUploadProgress(percentCompleted);
          },
        }
      );

      if (
        response.data &&
        response.data.protocolo &&
        response.data.protocolo._id
      ) {
        const createdProtocoloId = response.data.protocolo._id;
        navigate(`/protocolo/${createdProtocoloId}`);
      } else {
        throw new Error("ID do protocolo não foi retornado");
      }
    } catch (error) {
      console.error("Erro ao criar protocolo:", error);
      setError("Erro ao criar protocolo. Por favor, tente novamente.");
    } finally {
      setIsSubmitting(false); // Desativa o estado de envio
    }
  };

  const renderTipoProjetoStep = () => (
    <>
      <h4>Seleção do Subtipo de Projeto</h4>
      <Form.Group className="mb-3">
        <Form.Label>Subtipo de Projeto</Form.Label>
        <Form.Control
          as="select"
          name="subtipoProjeto"
          value={subtipoProjeto}
          onChange={(e) => setSubtipoProjeto(e.target.value)}
          required
        >
          <option value="">Selecione o subtipo de projeto</option>
          <option value="botaFora">Bota-fora</option>
          <option value="captacaoSuperficial">Captação Superficial</option>
          <option value="sistemaEsgotamentoSanitario">
            Sistema de Esgotamento Sanitário
          </option>
        </Form.Control>
      </Form.Group>
    </>
  );

  const renderDadosGerais = () => (
    <>
      <h4>Dados Gerais</h4>
      <Form.Group className="mb-3">
        <Form.Label>Nome da Solicitação (Objeto)</Form.Label>
        <Form.Control
          type="text"
          name="nomeObjeto"
          value={formData.nomeObjeto}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Ofício da Solicitação (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="oficioSolicitacao"
          onChange={handleChange}
          required
        />
      </Form.Group>
    </>
  );

  // Renderizações para as etapas específicas
  const renderBotaForaStep = () => (
    <>
      <h4>Bota-fora</h4>
      <Form.Group className="mb-3">
        <Form.Label>
          Documentos do Prefeito (Ata de Posse, Diploma, RG, CPF e Comprovante
          de Endereço)
        </Form.Label>
        <Form.Control
          type="file"
          name="documentosPrefeito"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Área Proposta para Locação do Bota-fora (PDF)</Form.Label>
        <Form.Control type="file" name="areaProposta" onChange={handleChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Relatório Fotográfico da Área (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="relatorioFotografico"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Documento da Área Atualizado (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="documentoArea"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Imagem do Polígono da Área (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="imagemPoligono"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Procuração do Prefeito para Licenciamento Ambiental (PDF)
        </Form.Label>
        <Form.Control
          type="file"
          name="procuracaoPrefeito"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Declaração de Uso e Ocupação do Solo (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="declaracaoUsoSolo"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Pagamento do Boleto da ART Ambiental (PDF)</Form.Label>
        <Form.Control type="file" name="pagamentoArt" onChange={handleChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Comprovante de Ligação de Água ou Declaração de Não Utilização de Água
          (PDF)
        </Form.Label>
        <Form.Control
          type="file"
          name="comprovanteAgua"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ART do Engenheiro do Município (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="artEngenheiro"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderCaptacaoSuperficialStep = () => (
    <>
      <h4>Captação Superficial</h4>
      <Form.Group className="mb-3">
        <Form.Label>
          Identificação do Corpo Hídrico de Captação (Tipo e Nome)
        </Form.Label>
        <Form.Control
          type="text"
          name="identificacaoCorpoHidrico"
          value={formData.identificacaoCorpoHidrico}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Coordenadas Geográficas do Ponto de Captação (GMS)
        </Form.Label>
        <Form.Control
          type="text"
          name="coordenadasPontoCaptacao"
          value={formData.coordenadasPontoCaptacao}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Identificação do Sistema de Captação (Gravidade, Bombeamento, etc.)
        </Form.Label>
        <Form.Control
          type="text"
          name="identificacaoSistemaCaptacao"
          value={formData.identificacaoSistemaCaptacao}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Dados Técnicos da Captação (População Atendida, Consumo per Capita,
          Vazão Final, etc.)
        </Form.Label>
        <Form.Control
          type="text"
          name="dadosTecnicosCaptacao"
          value={formData.dadosTecnicosCaptacao}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Procuração do Prefeito para Licenciamento Ambiental (PDF)
        </Form.Label>
        <Form.Control
          type="file"
          name="procuracaoPrefeito"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Documentos do Prefeito (Ata de Posse, Diploma, RG, CPF e Comprovante
          de Endereço)
        </Form.Label>
        <Form.Control
          type="file"
          name="documentosPrefeito"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Publicação do Pedido de Outorga no Diário Oficial de Mato Grosso -
          IOMAT (PDF)
        </Form.Label>
        <Form.Control
          type="file"
          name="publicacaoOutorga"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Declaração de Uso e Ocupação do Solo (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="declaracaoUsoSolo"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderSistemaEsgotamentoSanitarioStep = () => (
    <>
      <h4>Sistema de Esgotamento Sanitário</h4>
      <Form.Group className="mb-3">
        <Form.Label>
          Identificação do Corpo Hídrico Receptor (Tipo e Nome)
        </Form.Label>
        <Form.Control
          type="text"
          name="identificacaoCorpoHidricoReceptor"
          value={formData.identificacaoCorpoHidricoReceptor}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Coordenadas Geográficas do Ponto de Lançamento (GMS)
        </Form.Label>
        <Form.Control
          type="text"
          name="coordenadasPontoLancamento"
          value={formData.coordenadasPontoLancamento}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Dados Técnicos do Sistema (Vazão de Lançamento, Descrição do Processo
          de Tratamento, etc.)
        </Form.Label>
        <Form.Control
          type="text"
          name="dadosTecnicosSistema"
          value={formData.dadosTecnicosSistema}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Fluxograma Simplificado do Sistema de Tratamento de Esgotos (PDF)
        </Form.Label>
        <Form.Control
          type="file"
          name="fluxogramaSistemaTratamento"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Boletim de Análise do Efluente Bruto e Tratado (PDF)
        </Form.Label>
        <Form.Control
          type="file"
          name="boletimAnaliseEfluente"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Procuração do Prefeito para Licenciamento Ambiental (PDF)
        </Form.Label>
        <Form.Control
          type="file"
          name="procuracaoPrefeito"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          Documentos do Prefeito (Ata de Posse, Diploma, RG, CPF e Comprovante
          de Endereço)
        </Form.Label>
        <Form.Control
          type="file"
          name="documentosPrefeito"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Declaração de Uso e Ocupação do Solo (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="declaracaoUsoSolo"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  // Função para renderizar as etapas conforme o subtipo de projeto selecionado
  const renderSteps = () => {
    switch (subtipoProjeto) {
      case "botaFora":
        return renderBotaForaStep();
      case "captacaoSuperficial":
        return renderCaptacaoSuperficialStep();
      case "sistemaEsgotamentoSanitario":
        return renderSistemaEsgotamentoSanitarioStep();
      default:
        return null;
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      {uploadProgress > 0 && uploadProgress < 100 && (
        <ProgressBar
          now={uploadProgress}
          label={`${uploadProgress}%`}
          className="mb-3"
        />
      )}

      {step === 1 && renderTipoProjetoStep()}
      {step === 2 && renderDadosGerais()}
      {step >= 3 && renderSteps()}

      <div className="d-flex justify-content-between mt-4">
        {step > 1 && (
          <Button variant="secondary" onClick={handlePrevious}>
            Voltar
          </Button>
        )}
        {step < 3 && (
          <Button
            variant="primary"
            onClick={handleNext}
            disabled={!subtipoProjeto && step === 1}
          >
            Próxima Fase
          </Button>
        )}
        {step === 3 && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Enviando..." : "Enviar Solicitação"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default AmbientalInfraForm;
